export class FileUploadRequestBody{
  requestingSystem: string;
  claimNumber: string;
  files: FileClass[];
}

export class FileClass
{
  name: string;
  data: string;
}