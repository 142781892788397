import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormSteps } from 'src/app/enumerations/enum-form-steps';
import { ConfigService } from 'src/app/services/config.service';
import { FormService } from 'src/app/services/form.service';
import { ErrorMessages } from 'src/app/models/error-messages';
import { CountrySelectorComponent, InputDropdownlistComponent, InputFieldDateComponent, InputFieldTextComponent, InputFieldTextSecretComponent, StateSelectorComponent, provideDateMask } from '@ds/claims-portal-ui-components';
import { FormGroupComponent } from '../form-group/form-group.component';
import { NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-form-group-beneficiary',
  templateUrl: './form-group-beneficiary.component.html',
  styleUrls: ['./form-group-beneficiary.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgIf, MatButtonModule, FormGroupComponent, InputDropdownlistComponent, InputFieldTextSecretComponent, InputFieldTextComponent, InputFieldDateComponent, StateSelectorComponent, CountrySelectorComponent],
  providers: [
    provideDateMask()
  ]
})
export class FormGroupBeneficiaryComponent implements OnInit {
  @Input() bene:FormGroup;
  @Input() isPrimaryBene : boolean;
   public errorMsgs = new ErrorMessages();
  constructor(
    public formService: FormService,
    public configService: ConfigService
  ) { }

  ngOnInit(): void {
    /** If user does not have bene contact info but changes the answer to Yes in summary page,
        marking the formGroupBene as touched will show errors when form is shown */
    if(this.formService.formStep === FormSteps.Summary){
      this.formService.ccfBene.markAllAsTouched();
    }
  }

  onChange(){
    if(!this.formService.hasAdditionalBenes) {
      this.formService.ccfAdditionalBenes.disable()
      for (let i = this.formService.ccfAdditionalBenes.length-1; i >= 0; i--) {
        this.formService.ccfAdditionalBenes.removeAt(i);
      }
    }else{
      this.formService.ccfAdditionalBenes.enable()
    }
  }

}
