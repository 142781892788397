export const RoutePath = {
    home: '',
    claimForm: 'claim-form',
    claimSubmitSuccess: 'claim-submit-success',
    fileUpload: 'document-upload',
    fileUploadSuccess: 'document-upload-success',
    error: '**'
}

export const RouteTitle = {
    claimForm: 'Claim Form',
    home: 'TruStage Claims',
    claimSubmitSuccess: 'Claim Submitted',
    fileUpload: 'Document Upload',
    fileUploadSuccess: 'Document Submitted',
    error: 'Error'
}

