import { Component, OnInit } from '@angular/core';
import { Predetermined } from 'src/app/enumerations/enum-predetermined';
import { ConfigService } from 'src/app/services/config.service';
import { FormService } from 'src/app/services/form.service';
import { IllnessType } from 'src/app/enumerations/enum-illness-type';
import { FormArray, FormsModule, ReactiveFormsModule} from '@angular/forms';
import * as moment from 'moment';
import { FormGroupProperties as fp} from "src/app/models/constants/form-group-properties";
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { InputFieldTextComponent, StateSelectorComponent, InputFieldDateComponent, InputDropdownlistComponent, InputFieldYesnounsureComponent, InputFieldTextLargeComponent, InputFieldDaterangeComponent, InputFieldDaterangeAdditionalComponent, provideDateMask } from '@ds/claims-portal-ui-components';

@Component({
  selector: 'app-form-group-insured',
  templateUrl: './form-group-insured.component.html',
  styleUrls: ['./form-group-insured.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule, InputFieldTextComponent, StateSelectorComponent, InputFieldDateComponent, NgIf, InputDropdownlistComponent, InputFieldYesnounsureComponent, InputFieldTextLargeComponent, InputFieldDaterangeComponent, InputFieldDaterangeAdditionalComponent, MatButtonModule],
  providers: [
    provideDateMask()
  ]
})
export class FormLifeInsuredComponent implements OnInit {
  getAccidentMaxChar(){
    return 200 - this.formService.ccfInsured.value.insuredAccidentDescription.length
  }

  isPrimaryInsuredOnCertificate_No(){
    return this.formService.ccfInsured.value.isPrimaryInsuredOnCertificate === Predetermined.No;
  }

  areYouPrimaryInsuredOnCertificate_No(){
    return this.formService.ccfInsured.value.areYouPrimaryInsuredOnCertificate === Predetermined.No;
  }

  livingPrimaryInsuredOnCertificate_hasValue(){
    return this.formService.ccfInsured.value.livingPrimaryInsuredOnCertificate?.length > 0;
  }

  hasInsuredPassedAway_Yes(){
    return this.formService.ccfInsured.value.hasInsuredPassedAway === Predetermined.Yes;
  }

  areYouPrimaryInsuredOnCertificate_Unsure(){
    return this.formService.ccfInsured.value.areYouPrimaryInsuredOnCertificate === Predetermined.Unsure;
  }

  illnessType_Cancer(){
    return this.formService.ccfInsured.value.illnessType == IllnessType.Cancer;
  }

  illnessType_Other(){
    return this.formService.ccfInsured.value.illnessType == IllnessType.Other;
  }

  selectionChange_isPrimaryInsuredOnCertificate(){
    // isPrimaryInsuredOnCertificate is the parent for Critical Illness so this works in reverse
    if(this.formService.isAccidentalDeathLoss()){
      if(!this.isPrimaryInsuredOnCertificate_No()){
        //if not no, set cascading values to empty string and disable controls.
        
        this.formService.ccfInsured.controls.areYouPrimaryInsuredOnCertificate.setValue('');
        this.formService.ccfInsured.controls.areYouPrimaryInsuredOnCertificate.disable();
        this.selectionChange_areYouPrimaryInsuredOnCertificate();
      }else{
        /** if no, 
         *    enable next question(s)
         *    disable formGroupInformant. At this point, the user may or may not need this form. Next question determines that **/
        this.formService.ccfInsured.controls.areYouPrimaryInsuredOnCertificate.enable();
        this.formService.ccfInformant.disable();
      }
    }else if(this.formService.isCriticalIllnessLoss()){
      this.selectionChange_isPrimaryInsuredOnCertificate_CriticalIllness();
    }
  }
  
  selectionChange_isPrimaryInsuredOnCertificate_CriticalIllness(){
    if(this.formService.ccfInsured.value.isPrimaryInsuredOnCertificate != Predetermined.No){
      this.formService.ccfInsured.controls.livingPrimaryInsuredOnCertificate.setValue('');
      this.formService.ccfInsured.controls.livingPrimaryInsuredOnCertificate.disable();
      this.input_livingPrimaryInsuredOnCertificate();
    }else{
      this.formService.ccfInsured.controls.livingPrimaryInsuredOnCertificate.enable();
      this.formService.ccfInsured.controls.relationshipToPrimaryInsuredOnCertificate.enable();
    }
  }

  selectionChange_areYouPrimaryInsuredOnCertificate(){
    if(!this.formService.isCriticalIllnessLoss()){
      if(this.formService.ccfInsured.value.areYouPrimaryInsuredOnCertificate != Predetermined.No){
        //if not no, set cascading values to empty string and disable controls
        this.formService.ccfInsured.controls.livingPrimaryInsuredOnCertificate.setValue('');
        this.formService.ccfInsured.controls.livingPrimaryInsuredOnCertificate.disable();
        this.input_livingPrimaryInsuredOnCertificate();
      }else{
        //if no, enable next question(s)
        this.formService.ccfInsured.controls.livingPrimaryInsuredOnCertificate.enable();
      }

      if(this.formService.ccfInsured.value.areYouPrimaryInsuredOnCertificate == Predetermined.Yes){
        //if yes, reset and disable Informant FormGroup. Ensure bene FormGroup is enabled, if claim/loss type has bene data.
        this.formService.ccfInformant.reset();
        this.formService.ccfInformant.disable();
        this.formService.updateFormGroup_nonBeneficiary();
      }else{
        //If not yes, enable Informant FormGroup
        this.formService.ccfInformant.enable();
      }
    }else if(this.formService.isCriticalIllnessLoss()){
      this.selectionChange_areYouPrimaryInsuredOnCertificate_CriticalIllness();
    }
  }

  selectionChange_areYouPrimaryInsuredOnCertificate_CriticalIllness(){
    if(this.formService.ccfInsured.value.areYouPrimaryInsuredOnCertificate != Predetermined.No){
      this.formService.ccfInsured.controls.isPrimaryInsuredOnCertificate.setValue('');
      this.formService.ccfInsured.controls.isPrimaryInsuredOnCertificate.disable();
      this.selectionChange_isPrimaryInsuredOnCertificate_CriticalIllness();

      if(this.formService.ccfInsured.value.areYouPrimaryInsuredOnCertificate == Predetermined.Yes) {
        this.formService.ccfInsured.controls.hasInsuredPassedAway.setValue('');
        this.formService.ccfInsured.controls.hasInsuredPassedAway.disable();
        this.selectionChange_hasInsuredPassedAway();
      }else{
        this.formService.ccfInsured.controls.hasInsuredPassedAway.enable();
      }
    }else{
      this.formService.ccfInsured.controls.isPrimaryInsuredOnCertificate.enable();
      this.formService.ccfInsured.controls.hasInsuredPassedAway.enable();
    }
    //Informant FormGroup is required for critical illness regardless of yes or no
    this.formService.ccfInformant.enable();
    if(this.formService.ccfInsured.value.areYouPrimaryInsuredOnCertificate == Predetermined.Yes){
      //Ensure bene FormGroup is enabled, if claim/loss type has bene data.
      this.formService.updateFormGroup_nonBeneficiary();
    }
  }

  input_livingPrimaryInsuredOnCertificate(){
    if(!this.livingPrimaryInsuredOnCertificate_hasValue()){
      //if no value in input, set cascading values to empty string and disable controls
      this.formService.ccfInsured.controls.relationshipToPrimaryInsuredOnCertificate.setValue('');
      this.formService.ccfInsured.controls.maritalStatusOfPrimaryInsuredAtTimeOfLoss.setValue('');
      this.formService.ccfInsured.controls.relationshipToPrimaryInsuredOnCertificate.disable();
      this.formService.ccfInsured.controls.maritalStatusOfPrimaryInsuredAtTimeOfLoss.disable();
    }else{
      //if value in input, enable next question(s)
      this.formService.ccfInsured.controls.relationshipToPrimaryInsuredOnCertificate.enable();
      this.formService.ccfInsured.controls.maritalStatusOfPrimaryInsuredAtTimeOfLoss.enable();
    }
  }

  selectionChange_hasInsuredPassedAway(){
    if(this.hasInsuredPassedAway_Yes()) {
      this.formService.ccfInsured.controls.insuredDateOfDeath.enable();
      this.formService.ccfInsured.controls.insuredObituary.enable();
    }else{
      this.formService.ccfInsured.controls.insuredDateOfDeath.setValue('');
      this.formService.ccfInsured.controls.insuredObituary.setValue('');

      this.formService.ccfInsured.controls.insuredDateOfDeath.disable();
      this.formService.ccfInsured.controls.insuredObituary.disable();
    }
  }

  selectionChange_illnessType(){
    if(!this.illnessType_Cancer()){
      this.formService.ccfInsured.controls.cancerType.setValue('');
      this.formService.ccfInsured.controls.cancerType.disable();
    }else{
      this.formService.ccfInsured.controls.cancerType.enable();
    }

    if(!this.illnessType_Other()){
      this.formService.ccfInsured.controls.medicalDiagnosis.setValue('');
      this.formService.ccfInsured.controls.medicalDiagnosis.disable();
    }else{
      this.formService.ccfInsured.controls.medicalDiagnosis.enable();
    }

  }

  hasValidDateRange(fromDate : Date, toDate : Date){
    let validFromDate = false
    let validToDate = false
    if(fromDate?.toString().length > 0 && moment(fromDate, this.formService.placeholder.dateFormat).isValid){
      validFromDate = true
    }
    if(toDate?.toString().length > 0 && moment(toDate, this.formService.placeholder.dateFormat).isValid && toDate >= fromDate){
      validToDate = true
    }
    return validFromDate && validToDate
  }

  input_clearDateArrayWhenInvalidDate(fromDate : Date, toDate : Date){
    if(!this.hasValidDateRange(fromDate, toDate)){
      (this.formService.ccfInsured.get(fp.insured.additionalHospitalizationDates) as FormArray).clear()
    }
  }

  get additionalHospitalizationDatesProperty(){
    return fp.insured.additionalHospitalizationDates;
  }

  get hospitalizationDatesFromProperty(){
    return fp.insured.hospitalizationFromDate;
  }

  get hospitalizationDatesToProperty(){
    return fp.insured.hospitalizationToDate;
  }

  constructor(
    public formService: FormService,
    public configService: ConfigService
  ) { }

  ngOnInit(): void {
    if(this.formService.isSummaryStep()){
      this.formService.ccfInsured.markAllAsTouched();
    }
  }

}
