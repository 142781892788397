import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormSteps } from 'src/app/enumerations/enum-form-steps';
import { FormService } from 'src/app/services/form.service';

@Component({
  selector: 'app-claim-submit-success',
  templateUrl: './claim-submit-success.component.html',
  styleUrls: ['./claim-submit-success.component.scss'],
  standalone: true,
  imports: [NgIf]
})
export class ClaimSubmitSuccessComponent implements OnInit {

  constructor(
    public formService: FormService
  ) { }

  ngOnInit(): void {
    //After claim submission, if user decides to press back, this will reset the claim process.
    this.formService.formStep = FormSteps.NotSet;
  }
}