import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms"
import { ClaimType } from "../enumerations/enum-claim-type";
import { LossType } from "../enumerations/enum-loss-type";
import { FormGroupProperties as fp} from "./constants/form-group-properties";


export class ConsumerClaimForm{
  formGroupInsured = new FormGroup({
    [fp.insured.claimType]: new FormControl(ClaimType.NotSet),
    [fp.insured.lossType]: new FormControl(LossType.NotSet),
    [fp.insured.insuredFirstName]: new FormControl(''),
    [fp.insured.insuredLastName]: new FormControl(''),
    [fp.insured.insuredOtherName]: new FormControl(''),
    [fp.insured.insuredAddress]: new FormControl(''),
    [fp.insured.insuredCity]: new FormControl(''),
    [fp.insured.insuredState]: new FormControl(''),
    [fp.insured.insuredZipCode]: new FormControl(''),
    [fp.insured.insuredBirthDate]: new FormControl(''),
    [fp.insured.insuredLast4SSN]: new FormControl(''),
    [fp.insured.insuredMaritalStatusAtTimeOfDeath]: new FormControl(''),
    [fp.insured.insuredDateOfDeath]: new FormControl(''),
    [fp.insured.insuredMannerOfDeath]: new FormControl(''),
    [fp.insured.insuredWasDeathDueToCovid19]: new FormControl(''),
    [fp.insured.insuredAccidentDescription]: new FormControl(''),
    [fp.insured.insuredDidDeathOccurInForeignCountry]: new FormControl(''),
    [fp.insured.insuredFuneralHomeName]: new FormControl(''),
    [fp.insured.insuredFuneralHomeCity]: new FormControl(''),
    [fp.insured.insuredFuneralHomeState]: new FormControl(''),
    [fp.insured.insuredObituary]: new FormControl(''),
    [fp.insured.insuredPolicyNumber]: new FormControl(''),
    [fp.insured.insuredMaritalStatusAtTimeOfLoss]: new FormControl(''),
    [fp.insured.accidentDate]: new FormControl(''), 
    [fp.insured.accidentType]: new FormControl(''),
    [fp.insured.hospitalizationFromDate]: new FormControl(''),
    [fp.insured.hospitalizationToDate]: new FormControl(''),
    [fp.insured.additionalHospitalizationDates]: new FormArray([]),
    [fp.insured.certificateNumber]: new FormControl(''),  
    [fp.insured.isPrimaryInsuredOnCertificate]: new FormControl(''),
    [fp.insured.areYouPrimaryInsuredOnCertificate]: new FormControl(''),
    [fp.insured.livingPrimaryInsuredOnCertificate]: new FormControl(''),
    [fp.insured.relationshipToPrimaryInsuredOnCertificate]: new FormControl(''),
    [fp.insured.maritalStatusOfPrimaryInsuredAtTimeOfLoss]: new FormControl(''), 
    [fp.insured.dismembermentDate]: new FormControl(''),   
    [fp.insured.insuredSpecificLoss]: new FormControl(''),
    [fp.insured.didLossOccurInForeignCountry]: new FormControl(),
    [fp.insured.allOtherDatesOfHospitalization]: new FormControl(''),
    [fp.insured.illnessType]: new FormControl(''),
    [fp.insured.cancerType]: new FormControl(''),
    [fp.insured.medicalDiagnosis]: new FormControl(''),
    [fp.insured.diagnosisDate]: new FormControl(''),
    [fp.insured.hasInsuredPassedAway]: new FormControl(''),
    [fp.insured.causeOfDeath]: new FormControl('')
  });
  formGroupInformant = new FormGroup({
    [fp.informant.informantFirstName]: new FormControl(''),
    [fp.informant.informantLastName]: new FormControl(''),
    [fp.informant.informantPhone]: new FormControl(''),
    [fp.informant.informantRelationshipToInsured]: new FormControl(''),
    [fp.informant.informantIsBeneficiary]: new FormControl(''),
    [fp.informant.informantBeneficiaryContactInfo]: new FormControl(''),
    [fp.informant.informantEmail]: new FormControl('')
  });
  formGroupBene = new FormGroup({
    [fp.bene.beneFirstName]: new FormControl(''),
    [fp.bene.beneLastName]: new FormControl(''),
    [fp.bene.beneCountry]: new FormControl(''),
    [fp.bene.beneOtherCountryName]: new FormControl(''),
    [fp.bene.beneAddress]: new FormControl(''),
    [fp.bene.beneCity]: new FormControl(''),
    [fp.bene.beneState]: new FormControl(''),
    [fp.bene.beneZipCode]: new FormControl(''),
    [fp.bene.benePhone]: new FormControl(''),
    [fp.bene.beneEmail]: new FormControl(''),
    [fp.bene.beneGender]: new FormControl(''),
    [fp.bene.beneBirthDate]: new FormControl(''),
    [fp.bene.beneSSN]: new FormControl(''),
    [fp.bene.hasAdditionalBenes]: new FormControl('')
  });

  formGroupAddlBenes = new FormArray([
  ]);

  formGroupRecaptcha = new FormGroup({
    [fp.recaptcha.recaptcha]: new FormControl('', Validators.required)
  });
}
