import { HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";

export class ApiPath {

    private _opBasePath:string = environment.onPremAPIC.apiBasePath;
    private _opClientId:string = environment.onPremAPIC.clientID;

    private _guaranteedDelivery:string = this._opBasePath + 'consumer-claims-guaranteed-delivery-api';
    private _featureFlags:string = this._opBasePath + 'claims-app-configuration/featureflags'
    private _authWrapper: string = environment.cloudAPIC.apiBasePath + 'claims-authentication-wrapper-api'

    private _apicBaseHeaders:HttpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        'X-IBM-Client-Id': this._opClientId
    })

    apicBaseHeadersBearerToken(token){
        return new HttpHeaders({
        'X-IBM-Client-Id': this._opClientId,
        'Authorization': 'Bearer ' + token
        });
    }

    apicBaseHeadersRecaptchaToken(recaptchaToken){
        return new HttpHeaders({
            'Content-Type': 'application/json',
            'X-IBM-Client-Id': this._opClientId,
            'X-Recaptcha-Response': recaptchaToken
        });
    }

    apicBaseHeadersRecaptchaTokenFileUpload(recaptchaToken){
        return new HttpHeaders({
            'Ocp-Apim-Subscription-Key': environment.cloudAPIC.clientID,
            'X-Recaptcha-Response': recaptchaToken
        });
    }

    get guaranteedDelivery(){
        return this._guaranteedDelivery;
    }

    get apicBaseHeaders(){
        return this._apicBaseHeaders;
    }

    get featureFlags(){
        return this._featureFlags;
    }
    
}