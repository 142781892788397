import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NavRightComponent } from './components/ribbons/nav-right/nav-right.component';
import { HeaderComponent } from './components/ribbons/header/header.component';
import { FooterComponent } from './components/ribbons/footer/footer.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true, 
  imports: [RouterOutlet, NavRightComponent, HeaderComponent, FooterComponent]
})

export class AppComponent {
  title = 'consumer-claims-portal';
}