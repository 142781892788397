import { IConsumerClaimSchema } from "../interfaces/IConsumerClaimSchema";
import { Claim } from "./claim";
import { ConsumerClaimDocument } from "./document";


export class ConsumerClaimSchema implements IConsumerClaimSchema{
    claim: Claim;
    documents: Array<ConsumerClaimDocument>;

    constructor(){
        this.claim = new Claim();
        this.documents = Array<ConsumerClaimDocument>();
    }
}