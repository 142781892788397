export const FormGroupProperties = {
    insured: {
        claimType: 'claimType',
        lossType: 'lossType',
        insuredFirstName: 'insuredFirstName',
        insuredLastName: 'insuredLastName',
        insuredOtherName: 'insuredOtherName',
        insuredAddress: 'insuredAddress',
        insuredCity: 'insuredCity',
        insuredState: 'insuredState',
        insuredZipCode: 'insuredZipCode',
        insuredBirthDate: 'insuredBirthDate',
        insuredLast4SSN: 'insuredLast4SSN',
        insuredMaritalStatusAtTimeOfDeath: 'insuredMaritalStatusAtTimeOfDeath',
        insuredDateOfDeath: 'insuredDateOfDeath',
        insuredMannerOfDeath: 'insuredMannerOfDeath',
        insuredWasDeathDueToCovid19: 'insuredWasDeathDueToCovid19',
        insuredAccidentDescription: 'insuredAccidentDescription',
        insuredDidDeathOccurInForeignCountry: 'insuredDidDeathOccurInForeignCountry',
        insuredFuneralHomeName: 'insuredFuneralHomeName',
        insuredFuneralHomeCity: 'insuredFuneralHomeCity',
        insuredFuneralHomeState: 'insuredFuneralHomeState',
        insuredObituary: 'insuredObituary',
        insuredPolicyNumber: 'insuredPolicyNumber',
        insuredMaritalStatusAtTimeOfLoss: 'insuredMaritalStatusAtTimeOfLoss',
        accidentDate: 'accidentDate', 
        accidentType: 'accidentType',
        hospitalizationFromDate: 'hospitalizationFromDate',
        hospitalizationToDate: 'hospitalizationToDate',
        additionalHospitalizationDates: 'additionalHospitalizationDates',
        certificateNumber: 'certificateNumber',  
        isPrimaryInsuredOnCertificate: 'isPrimaryInsuredOnCertificate',
        areYouPrimaryInsuredOnCertificate: 'areYouPrimaryInsuredOnCertificate',
        livingPrimaryInsuredOnCertificate: 'livingPrimaryInsuredOnCertificate',
        relationshipToPrimaryInsuredOnCertificate: 'relationshipToPrimaryInsuredOnCertificate',
        maritalStatusOfPrimaryInsuredAtTimeOfLoss: 'maritalStatusOfPrimaryInsuredAtTimeOfLoss', 
        dismembermentDate: 'dismembermentDate',   
        insuredSpecificLoss: 'insuredSpecificLoss',
        didLossOccurInForeignCountry: 'didLossOccurInForeignCountry',
        allOtherDatesOfHospitalization: 'allOtherDatesOfHospitalization',
        illnessType: 'illnessType',
        cancerType: 'cancerType',
        medicalDiagnosis: 'medicalDiagnosis',
        diagnosisDate: 'diagnosisDate',
        hasInsuredPassedAway: 'hasInsuredPassedAway',
        causeOfDeath: 'causeOfDeath'
    },
    informant: {
        informantFirstName: 'informantFirstName',
        informantLastName: 'informantLastName',
        informantPhone: 'informantPhone',
        informantRelationshipToInsured: 'informantRelationshipToInsured',
        informantIsBeneficiary: 'informantIsBeneficiary',
        informantBeneficiaryContactInfo: 'informantBeneficiaryContactInfo',
        informantEmail: 'informantEmail'
    },
    bene: {
        beneFirstName: 'beneFirstName',
        beneLastName: 'beneLastName',
        beneCountry: 'beneCountry',
        beneOtherCountryName: 'beneOtherCountryName',
        beneAddress: 'beneAddress',
        beneCity: 'beneCity',
        beneState: 'beneState',
        beneZipCode: 'beneZipCode',
        benePhone: 'benePhone',
        beneEmail: 'beneEmail',
        beneGender: 'beneGender',
        beneBirthDate: 'beneBirthDate',
        beneSSN: 'beneSSN',
        hasAdditionalBenes: 'hasAdditionalBenes'
    },
    recaptcha: {
        recaptcha: 'recaptcha'
    },

    additionalHospitalizationDate: {
        startDate(i : number) : string{ return 'addlHospitalizationStartDate_'+(i)},
        endDate(i : number) : string{ return 'addlHospitalizationEndDate_'+(i)}
    }
}