import { IInsured } from "../interfaces/IInsured";

export class Insured implements IInsured{
    "firstName": string;
    "lastName": string;
    "otherName": string;
    "policyNumber": string;
    "address": string;
    "city": string;
    "state": string;
    "zipCode": string;
    "birthDate": string;
    "last4SSN": number;
    "maritalStatusOnDeath": string;

    constructor(){
        this.birthDate = '';
        this.address = '';
        this.city = '';
        this.firstName = '';
        this.last4SSN = 0;
        this.lastName = '';
        this.maritalStatusOnDeath = '';
        this.otherName = '';
        this.policyNumber = '';
        this.state = '';
        this.zipCode = '';
    }
}