import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ContactInfoService } from 'src/app/services/contact-info.service';
import { FeatureFlagsService } from 'src/app/services/feature-flags.service';

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  standalone: true,
  imports: [NgIf]
})
export class NavRightComponent implements OnInit {

  sbtDayOneEnabled:boolean = this.featureFlagsService.featureFlags.sbtDayOne;

  constructor(
    public contactInfoService: ContactInfoService,
    private featureFlagsService: FeatureFlagsService

  ) { }

  ngOnInit(): void {
  }

}
