import { IConsumerClaimProperty } from "../interfaces/IConsumerClaimProperty";

export class ConsumerClaimProperty implements IConsumerClaimProperty{
    key:string;
    name:string;
    value:string;

    constructor(){
        this.key = null;
        this.name = null;
        this.value = null;
    }
}

