import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { ApiPath } from '../models/api';
import { FileUploadRequestBody } from '../models/fileupload-request-body';
import { FileUploadService } from './file-upload.service';
import { RouteToService } from 'src/app/services/route-to.service';

@Injectable({
  providedIn: 'root'
})
export class AuthWrapperService {

  api:ApiPath = new ApiPath();
  submitRequestBody: FileUploadRequestBody = new FileUploadRequestBody()
  formData:FormData;
  loadedFileCount:number;

  constructor(private http: HttpClient) { }

  uploadFile(fileUploadService: FileUploadService, routeToService: RouteToService, strRequestingSystem:string){
    this.formData = new FormData(); 
    this.loadedFileCount = 0;

    let fileArray;
    fileArray = fileUploadService.fileUploadFormGroup.get('file').value;
    fileArray.forEach((fileObj) => {
      this.formData.append(fileObj.name, fileObj);   
    })

    this.formData.append("requestingSystem", strRequestingSystem);
    this.formData.append("claimNumber", fileUploadService.fileUploadFormGroup.controls.claimNumber.value);
    let fileUploadHeaders = this.api.apicBaseHeadersRecaptchaTokenFileUpload(fileUploadService.fileUploadRecaptcha.controls.recaptcha.value)
    var response = this.http.post(environment.cloudAPIC.apiBasePath + "claims-authentication-wrapper-api/" + environment.cloudAPIC.apiVersion + "/FileUpload", this.formData, { headers: fileUploadHeaders, responseType:'text'})
    
    response.subscribe((data) => {
    },
    (err) => {
      fileUploadService.fileSubmitError = true;
      fileUploadService.statusCode = err.status;
      fileUploadService.fileSubmitSuccess = false;
      fileUploadService.uploadingFile = false;
    },
    () => {
      fileUploadService.fileSubmitError = false;
      fileUploadService.fileSubmitSuccess = true;
      fileUploadService.uploadingFile = false;
      routeToService.fileUploadSuccess();
    });

  }
}
