import { Injectable } from '@angular/core';

import { FileUploadService } from 'src/app/services/file-upload.service';
import { RouteToService } from '../services/route-to.service';

@Injectable({
  providedIn: 'root'
})
export class FileUploadSuccessGuard  {

  constructor(
    private fileUploadService: FileUploadService,
    private routeToService: RouteToService
  ) { }

  canActivate() {
      if (this.fileUploadService.fileSubmitSuccess == false){
        this.routeToService.fileUploadHome();
        return false;
      }
      return true;
  }
  
}
