import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/services/config.service';
import { FeatureFlagsService } from 'src/app/services/feature-flags.service';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { AuthWrapperService } from 'src/app/services/auth-wrapper.service';
import { RouteToService } from 'src/app/services/route-to.service';
import { UrlRedirectService } from 'src/app/services/url-redirect.service';
import { InputFieldTextComponent, RecaptchaComponent, FileUploadComponent as _FileUploadComponent} from '@ds/claims-portal-ui-components';
import { NgIf } from '@angular/common';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  standalone: true,
  imports: [RecaptchaComponent,
            _FileUploadComponent, 
            InputFieldTextComponent, 
            ReactiveFormsModule, 
            NgIf,
            MatButton]
})
export class FileUploadComponent implements OnInit {

  sizeLimitMB:number = 10; //Make this configurable when we can send configurations via AppConfigurationAPI
  allowedFileTypes:string = ".xls,.xlsx,.png,.jpg,.jpeg,.tif,.tiff,.doc,.docx,.pdf,.txt";
  allowedFileTypesArray:string[] = this.allowedFileTypes.toUpperCase().split(',');
  showErrorAlert:boolean;
  errorMessageAlert:string;
  totalFileSize:number = 0;
  recaptchaRequired:boolean= this.featureFlagsService.featureFlags.fileUploadRecaptchaFlag;

  constructor(
    public fileUploadService: FileUploadService,
    public configService: ConfigService,
    private featureFlagsService: FeatureFlagsService,
    private authWrapperService: AuthWrapperService,
    private routeToService: RouteToService,
    public urlRedirectService: UrlRedirectService
    ) { }

  ngOnInit(): void {
    this.urlRedirectService.redirect()
  }

  onSubmit(formGroup:FormGroup) {
    this.fileUploadService.uploadingFile = false;
    this.fileUploadService.fileSubmitError = false;
    this.fileUploadService.fileSubmitSuccess = false;

    if(formGroup.invalid){
      formGroup.markAllAsTouched();
      return;
    }

    if(this.recaptchaRequired && this.fileUploadService.fileUploadRecaptcha.invalid === true){
      this.fileUploadService.fileUploadRecaptcha.controls.recaptcha.markAsTouched();
    }else{
      this.fileUploadService.uploadingFile = true;
      this.authWrapperService.uploadFile(this.fileUploadService, this.routeToService, 'CCP');
    }
  }

  showRecaptchaErrorMessage(){
    return (this.recaptchaRequired && this.fileUploadService.fileUploadRecaptcha.controls.recaptcha.touched && this.fileUploadService.fileUploadRecaptcha.controls.recaptcha.value === "")
  }

  errorMessageRequired(){
    return this.fileUploadService.errMsg.fileUploadRequired(this.sizeLimitMB);
  }

}
