import { Injectable } from '@angular/core';
import { IContactInfo } from '@ds/interfaces/src/lib/interfaces/i-contact-info';
import { FeatureFlagsService } from 'src/app/services/feature-flags.service';

@Injectable({
  providedIn: 'root'
})
export class ContactInfoService implements IContactInfo{

  sbtDayOneEnabled:boolean = this.featureFlagsService.featureFlags.sbtDayOne;

  phoneNumber:string = '1-800-779-5433'
  availableHours:string = '7:00 AM - 5:00 PM CT'
  availableDays:string = 'Monday - Friday'
  mailLine1:string = this.sbtDayOneEnabled ? 'TruStage' : 'CMFG Life Insurance Company'
  mailLine2:string = '2000 Heritage Way, Waverly IA'
  mailLine3:string = '50677-9202'
  mailLine4:string = ''
  faxNumber:string = '1-608-236-8030'

  isMailLine4():boolean{
    return this.mailLine4.length > 0;
  }

  constructor(
    private featureFlagsService : FeatureFlagsService
  ) { }
}
