import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error-default',
  templateUrl: './error-default.component.html',
  styleUrls: ['./error-default.component.scss'],
  standalone: true
})
export class ErrorDefaultComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
