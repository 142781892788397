import { IBeneficiary } from "../interfaces/IBeneficiary";

export class Beneficiary implements IBeneficiary{
    "firstName": string;
    "lastName": string;
    "address": string;
    "city": string;
    "state": string; // two characters. ie, WI
    "zipCode": string;
    "country": string;
    "otherCountry": string;
    "birthDate": string; // MM/DD/YYYY
    "ssnToken": string; // full SSN. Gets sent via https then encrypted by via API
    "gender": string;
    "phone": string; // 999-999-9999
    "email": string;

    constructor(){
        this.address = '';
        this.birthDate = '';
        this.city = '';
        this.country = '';
        this.email = '';
        this.firstName = '';
        this.gender = '';
        this.lastName = '';
        this.otherCountry = '';
        this.phone = '';
        this.ssnToken = '';
        this.state = '';
        this.zipCode = '';
    }
}