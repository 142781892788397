<div class="right-nav-padding-bottom">
    <h4 class="h4-nd">CONTACT US</h4>
    <address>
        <h5 class="h5-nd">Phone:</h5>
        {{contactInfoService.phoneNumber}}<br>
        {{contactInfoService.availableHours}}<br>
        {{contactInfoService.availableDays}}<br><br>
        <h5 class="h5-nd">Mail:</h5>
        {{contactInfoService.mailLine1}}<br>
        {{contactInfoService.mailLine2}}<br>
        {{contactInfoService.mailLine3}}<br>
        <span *ngIf="contactInfoService.isMailLine4()">{{contactInfoService.mailLine4}}</span><br>
        <h5 class="h5-nd">Fax:</h5>
        {{contactInfoService.faxNumber}}
    </address>
    <h4 class="h4-nd">RESOURCES FOR YOU</h4>
    <div>
        <a href="https://www.trustage.com/my-account/help/claims-information-faqs?" target="_blank" title="(Opens in new window)" 
            aria-label="TruStage Claims FAQs" class="faq">Visit TruStage Claims FAQs</a>
    </div>
</div>
