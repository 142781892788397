import { Component, OnInit } from '@angular/core';
import { FormService } from 'src/app/services/form.service';
import { FormBuilder, FormGroup, FormsModule } from '@angular/forms';
import { ConfigService } from 'src/app/services/config.service';
import { ConsumerClaimForm } from 'src/app/models/consumer-claim-form';
import { FormSteps } from 'src/app/enumerations/enum-form-steps';
import { MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { ErrorMessages } from 'src/app/models/error-messages';
import { NgFor, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FormGroupBeneficiaryComponent } from '../form-group-beneficiary/form-group-beneficiary.component';

@Component({
  selector: 'app-form-group-additional-beneficiaries',
  templateUrl: './form-group-additional-beneficiaries.component.html',
  styleUrls: ['./form-group-additional-beneficiaries.component.scss'],
  standalone: true, 
  imports: [FormsModule, NgIf, MatTabsModule, NgFor, MatButtonModule, MatIconModule, FormGroupBeneficiaryComponent]
})
export class FormGroupAdditionalBeneficiariesComponent implements OnInit {
  
  public selectedIndex : number = 0;
  public errorMsgs = new ErrorMessages();

  constructor(
    public formService: FormService, 
    public fb: FormBuilder,
    public configService : ConfigService
  ) {}

  ngOnInit(): void {
    if(this.beneForms.length == 0) {
      this.addBene(null);
    }
  }
  
  get beneForms() {
    return this.formService.ccfAdditionalBenes;
   }

  addBene(tabGroup : MatTabGroup) {
    let bene = new ConsumerClaimForm().formGroupBene;
    this.beneForms.push(bene);
    
    if(this.formService.formStep == FormSteps.Summary){
      this.beneForms.markAllAsTouched();
    }
    if(tabGroup != null && this.beneForms.length > 0){
      tabGroup.selectedIndex = this.beneForms.length-1
    }
    
  }

  castAdditionalBene(additionalBene){
    return(additionalBene as FormGroup)
  }
  
  deleteBene(i) {
    this.beneForms.removeAt(i)
  }

  get invalidAdditionalBenes() : number[]{
    let invalidAdditionalBenes = new Array<number>()
    for(let index : number = 0; index<this.beneForms.controls.length;index++){
      let formGroup = this.beneForms.controls[index] as FormGroup
      if(formGroup.invalid){
        invalidAdditionalBenes.push(index)
      }
    }
    return invalidAdditionalBenes
  }

  jumpToError(index : number, el : HTMLElement){
    this.selectedIndex = index;
    this.scroll(el)
  }

  scroll(el : HTMLElement){
    el.scrollIntoView();
  }
}
