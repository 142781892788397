<div class="container-fluid footer-padding global-footer">
    <div class="row">
        <div *ngIf="!sbtDayOneEnabled" class="col-md-12">
            <h3 class="footer-links__title h3-nd">Family of Brands</h3>
        </div>
    </div>
    <div *ngIf="!sbtDayOneEnabled" class="row">
        <div class="col-md-12">
            <a href="https://www.cunamutual.com/" class="btn btn--link" target="_blank" title="(Opens in new window)"
                aria-label="CMFG Life Insurance Company">
                <img src="../../../../assets/cmfg/cuna-mutual-group-logo.png" aria-label="CMFG Life Insurance Company">
            </a>
            <a href="https://www.trustage.com/" class="btn btn--link"  target="_blank" title="(Opens in new window)"
                aria-label="TruStage Insurance Agency">
                <img src="../../../../assets/cmfg/trustage-logo-bw.png" aria-label="TruStage Insurance Agency">
            </a>
            <a href="https://cmannuities.com/" class="btn btn--link" target="_blank" title="(Opens in new window)"
                aria-label="CUNA Mutual Group">
                <img src="../../../../assets/cmfg/cuna-mutual-financial-group-logo.png" aria-label="CUNA Mutual Group">
            </a>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 footer-wording">
            <p *ngIf="!sbtDayOneEnabled ; else sbtDayOneText">TruStage® Insurance products and programs are made available through TruStage Insurance Agency, LLC and issued by 
                CMFG Life Insurance Company and other leading insurance companies. 
                The insurance offered is not a deposit, and is not federally insured, sold or guaranteed by any depository institution.</p>

            <ng-template #sbtDayOneText>
                <p>TruStage™ is the marketing name for TruStage Financial Group, Inc. its subsidiaries and affiliates. Securities distributed by CUNA Brokerage Services, Inc., 
                member FINRA/SIPC, a registered broker/dealer, 2000 Heritage Way, Waverly, IA, 50677. Insurance products are issued by CMFG Life Insurance Company, MEMBERS 
                Life Insurance Company, CUMIS Insurance Society Inc., CUMIS Specialty Insurance Society Inc., American Memorial Life Insurance Company, and Union Security 
                Insurance Company.</p>
            </ng-template>
            
            <p>Corporate Headquarters 5910 Mineral Point Road, Madison WI 53705</p>

            <p *ngIf="!sbtDayOneEnabled ; else sbtDayOneCopyright">© Copyright {{ copyrightYear }}, TruStage. All Rights Reserved.</p>
            <ng-template #sbtDayOneCopyright>
                <p>© TruStage. All Rights Reserved.</p>
            </ng-template>
        </div>
    </div>
</div>