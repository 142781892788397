import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tax-language',
  templateUrl: './tax-language.component.html',
  styleUrls: ['./tax-language.component.scss'],
  standalone: true
})
export class TaxLanguageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
