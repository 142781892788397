import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FeatureFlags } from '../models/feature-flags';
import { ApiPath } from '../models/api';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagsService {

  featureFlags;
  api:ApiPath = new ApiPath();

  constructor(private http: HttpClient) { }

  getFeatureFlags(): Promise<Object> {
    return this.http.get(this.api.featureFlags, { headers: this.api.apicBaseHeaders})
      .pipe(
        tap(config => {
          this.featureFlags = <FeatureFlags>config;
        })
      ).toPromise();
  }

}
