import { Component, OnInit } from '@angular/core';
import { UrlRedirectService } from 'src/app/services/url-redirect.service';
import { ClaimWorkflowComponent } from '../../reusables/claim-workflow/claim-workflow.component';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
  standalone: true,
  imports: [ClaimWorkflowComponent]
})
export class LandingPageComponent implements OnInit {

  constructor(
    public urlRedirectService: UrlRedirectService
    ) { }

  ngOnInit(): void {
    this.urlRedirectService.redirect()
  }

}
