<app-form-group-insured *ngIf="formService.isInsuredStep() || formService.isSummaryStep()"></app-form-group-insured>
<app-form-group-informant *ngIf="formService.isInformantStep() || (formService.ccfInformant.enabled && formService.isSummaryStep())"></app-form-group-informant>
<app-form-group-beneficiary [bene]="formService.ccfBene" [isPrimaryBene]="true"
    *ngIf="formService.isBeneficiaryStep() || (formService.ccfBene.enabled && formService.isSummaryStep())">
</app-form-group-beneficiary>
<app-form-group-additional-beneficiaries
    *ngIf="formService.hasAdditionalBenes && (formService.isAdditionalBeneficiaryStep() || (formService.ccfAdditionalBenes.enabled && formService.isSummaryStep()))">
</app-form-group-additional-beneficiaries>

<ds-recaptcha  
    *ngIf="formService.isSummaryStep()"
    [form]="formService.ccfRecaptcha"
    formControlNameInput="recaptcha"
    siteKey="{{configService.recaptchaSiteKey}}"
    divClass="col-md-5"
    labelClass="col-md-3"
    [showErrorMessage]="formService.isConsumerClaimFormInvalid()"
    errorMessage="{{formService.errMsg.requiredGeneric}}"
    ariaLabelInput="{{formService.lbl.recaptcha}}">
</ds-recaptcha>

<div class="row" *ngIf="formService.isSummaryStep()">
    <div class="col-md-6">
        <button type="button" (click)="formService.back()" mat-flat-button color="danger">Back</button>
    </div>
    <div class="col-md-6 right-align">
        <button *ngIf="formService.isSummaryStep()" type="submit" (click)="onSubmit()" [disabled]="formService.isConsumerClaimFormInvalid() || formService.submittingClaim" mat-flat-button color="success">Submit</button>
        <br><br>
        <p *ngIf="formService.submittingClaim">Submitting claim data...  <span class="spinner-border" role="status"></span></p>
    </div>
    <br><br>
    <p *ngIf="formService.isConsumerClaimFormInvalid()" class="alert alert-danger">
        One or more fields are invalid. Please correct any invalid field(s) and try again.
    </p>
    <p *ngIf="formService.claimSubmitError" class="alert alert-danger">
        An error occurred while submitting your claim. Please try again. If the error persists, please call {{contactInfoService.phoneNumber}} for help with submitting your claim.
    </p>
</div>