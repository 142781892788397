import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, FormArray} from '@angular/forms';
import { DropDownOptions } from '../models/dropdown-options';
import { ErrorMessages } from '../models/error-messages';
import { Labels } from '../models/labels';
import { ClaimType } from '../enumerations/enum-claim-type';
import { FormSteps } from '../enumerations/enum-form-steps';
import { Predetermined } from '../enumerations/enum-predetermined';
import { ConsumerClaimForm } from '../models/consumer-claim-form';
import { ClaimsPortalUiService } from '@ds/claims-portal-ui-components';
import { PlaceholderText } from '../models/placeholder-text';
import { LossType } from '../enumerations/enum-loss-type';
import { MannerOfDeath } from '../enumerations/enum-manner-of-death';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  lbl: Labels = new Labels();
  errMsg = new ErrorMessages();
  formStep: FormSteps = FormSteps.NotSet;
  consumerClaimForm:ConsumerClaimForm = new ConsumerClaimForm();
  options: DropDownOptions = new DropDownOptions();
  submittingClaim: boolean = false;
  claimSubmitSuccess: boolean = false;
  claimSubmitError: boolean = false;
  mask = this.claimsPortalUiService.masks
  placeholder = new PlaceholderText();

  constructor(
    private claimsPortalUiService: ClaimsPortalUiService,
    private fb: FormBuilder
  ) { }

  get hasAdditionalBenes(){
    return this.ccfBene.value.hasAdditionalBenes === Predetermined.Yes;
  }

  get ccfInsured(){
    return this.consumerClaimForm.formGroupInsured;
  }
  
  get ccfInformant(){
    return this.consumerClaimForm.formGroupInformant;
  }

  get ccfBene(){
    return this.consumerClaimForm.formGroupBene;
  }

  get ccfAdditionalBenes(){
    return this.consumerClaimForm.formGroupAddlBenes;
  }

  get ccfRecaptcha(){
    return this.consumerClaimForm.formGroupRecaptcha;
  }

  claimTypeNotSet(){
    return this.ccfInsured.value.claimType === ClaimType.NotSet;
  }

  lossTypeNotSet(){
    return this.ccfInsured.value.lossType === LossType.NotSet;
  }

  formStepsNotSet(){
    return this.formStep === FormSteps.NotSet;
  }

  isLifeClaim(){
    return this.ccfInsured.value.claimType === ClaimType.Life;
  }

  isADDClaim(){
    return this.ccfInsured.value.claimType === ClaimType.AccidentalDeathAndDismemberment;
  }

  isAccidentalDeathLoss(){
    return this.ccfInsured.value.lossType === LossType.AccidentalDeath;
  }

  isAccidentalDismembermentLoss(){
    return this.ccfInsured.value.lossType === LossType.AccidentalDismemberment;
  }

  isNaturalDeathLoss(){
    return this.ccfInsured.value.lossType === LossType.NaturalDeath;
  }

  isAccidentalHospitalizationLoss(){
    return this.ccfInsured.value.lossType === LossType.AccidentalHospitalization;
  }

  isCriticalIllnessLoss(){
    return this.ccfInsured.value.lossType === LossType.CriticalIllness;
  }

  isInsuredStep(){
    return this.formStep === FormSteps.Insured
  }

  isInformantStep(){
    return this.formStep === FormSteps.Informant
  }

  isBeneficiaryStep(){
    return this.formStep === FormSteps.Beneficiary
  }

  isSummaryStep(){
    return this.formStep === FormSteps.Summary
  }

  isAdditionalBeneficiaryStep(){
    return this.formStep === FormSteps.AdditionalBeneficiary
  }
  
  isMannerOfDeathNatural(){
    return this.ccfInsured.value.insuredMannerOfDeath === MannerOfDeath.Natural //Natural
  }

  isMannerOfDeathAccidental(){
    return this.ccfInsured.value.insuredMannerOfDeath === MannerOfDeath.Accidental //Accidental
  }

  isBeneFormCountryOther(form : FormGroup){
    return form.value.beneCountry === Predetermined.Other;
  }

  isBeneFormCountryNotUsa(form : FormGroup){
    return form.value.beneCountry !== Predetermined.usa;
  }

  informantHasBeneInfo(){
    return this.ccfInformant.value.informantBeneficiaryContactInfo === Predetermined.Yes;
  }

  informantIsBene(){
    return this.ccfInformant.value.informantIsBeneficiary === Predetermined.Yes;
  }

  isRecaptchaTouched(){
    return this.ccfRecaptcha.controls.recaptcha.touched;
  }

  ADD_AccidentalDeath_beneIsPrimaryInsuredOnCertificate(){
    return this.isADDClaim() && this.isAccidentalDeathLoss() && this.ccfInsured.value.areYouPrimaryInsuredOnCertificate === Predetermined.Yes
  }

  isInformantPrimaryInsured(){
    return this.ccfInsured.value.areYouPrimaryInsuredOnCertificate === Predetermined.Yes;
  }

  isConsumerClaimFormInvalid():boolean{
    let isInvalid: boolean = false;
    if(this.ccfInsured.invalid === true){
      isInvalid = true;
    }else if(this.ccfInformant.invalid === true){
      isInvalid = true;
    }else if(this.ccfBene.invalid === true){
      isInvalid = true;
    }else if(this.ccfAdditionalBenes.invalid === true){
      isInvalid = true;
    }else if(this.isRecaptchaTouched() && this.ccfRecaptcha.invalid === true){
      isInvalid = true;
    }
    return isInvalid;
  }

  reverseFormStep(){
    switch(this.formStep) { 
      case FormSteps.Summary: { 
        if(this.hasAdditionalBenes&& this.ccfAdditionalBenes.enabled){
          this.formStep = FormSteps.AdditionalBeneficiary
        }else if(this.ccfBene.enabled){
          this.formStep = FormSteps.Beneficiary
        }
        else if(this.ccfInformant.enabled){
          this.formStep = FormSteps.Informant
        }else{
          this.formStep = FormSteps.Insured
        }
      break; 
      } 
      case FormSteps.AdditionalBeneficiary: { 
        if(this.ccfBene.enabled){
            this.formStep = FormSteps.Beneficiary
          }else if(this.ccfInformant.enabled){
            this.formStep = FormSteps.Informant
          }else{
            this.formStep = FormSteps.Insured
          }
      break; 
      } 
      case FormSteps.Beneficiary: { 
        if(this.ccfInformant.enabled){
            this.formStep = FormSteps.Informant
          }else{
            this.formStep = FormSteps.Insured
          }
      break; 
      } 
      case FormSteps.Informant: { 
        this.formStep = FormSteps.Insured
      break; 
      } 
    } 
  }

  continueFormStep(){
    switch(this.formStep) { 
      case FormSteps.Insured: { 
        if(this.ccfInformant.enabled){
          this.formStep = FormSteps.Informant
        }else if(this.ccfBene.enabled){
          this.formStep = FormSteps.Beneficiary
        }
        else{
          this.formStep = FormSteps.Summary
        }
      break; 
      } 
      case FormSteps.Informant: { 
        if(this.ccfBene.enabled){
          this.formStep = FormSteps.Beneficiary
        }else{
          this.formStep = FormSteps.Summary
        }
      break; 
      } 
      case FormSteps.Beneficiary: { 
        if(this.hasAdditionalBenes && this.ccfAdditionalBenes.enabled){
          this.formStep = FormSteps.AdditionalBeneficiary
        }else{
          this.formStep = FormSteps.Summary
        }
      break; 
      }
      case FormSteps.AdditionalBeneficiary: { 
        this.formStep = FormSteps.Summary
      break; 
      }
    } 
  }

  back(){
    this.reverseFormStep();
    this.scrollToTop();
  }

  continue(){
    this.continueFormStep();
    this.scrollToTop();
  }


  onSubmit(formGroup:FormGroup){
    if(formGroup.invalid){
      formGroup.markAllAsTouched();
    }else{
      this.continue()
    }
  }

  onSubmitArray(formArray:FormArray){
    if(formArray.invalid){
      formArray.markAllAsTouched();
    }else{
      this.continue()
    }
  }

  /** Scrolls to the top of the page. */
  scrollToTop(){
    window.scrollTo(0, 0)
  }

  updateFormControls_insuredMannerOfDeath(){
    if(this.isLifeClaim()){
      if(this.ccfInsured.value.insuredMannerOfDeath !== MannerOfDeath.Natural){
        //Natural
        this.ccfInsured.controls.insuredWasDeathDueToCovid19.disable();
      }else{
        this.ccfInsured.controls.insuredWasDeathDueToCovid19.enable();
      }
  
      if(this.ccfInsured.value.insuredMannerOfDeath !== MannerOfDeath.Accidental){
        //Accidental
        this.ccfInsured.controls.insuredAccidentDescription.disable();
      }else{
        this.ccfInsured.controls.insuredAccidentDescription.enable();
      }
    }
    
    if(this.isADDClaim() && this.isAccidentalDeathLoss()){
      if(this.ccfInsured.value.insuredMannerOfDeath !== MannerOfDeath.Accidental){
        this.ccfInsured.controls.accidentType.disable();
      }else{
        this.ccfInsured.controls.accidentType.enable();
      }
    }
  }

  updateFormGroup_informantBeneficiaryContactInfo(){
    if(this.informantHasBeneInfo()){
      this.ccfBene.enable();
      this.ccfAdditionalBenes.enable();
    }else{
      this.ccfBene.disable();
      this.ccfAdditionalBenes.disable();
    }
  }

  updateFormGroup_nonBeneficiary(){
    if(this.bypassBeneStep){
      this.ccfBene.disable()
    }else{
      this.ccfBene.enable()
    }
  }

  get bypassBeneStep(){
    return this.isADDClaim() && (this.isNaturalDeathLoss() || this.isCriticalIllnessLoss() || this.isAccidentalDismembermentLoss() || this.isAccidentalHospitalizationLoss()) //add other loss types to not hit bene step here
  }

  updateFormGroup_informantIsBeneficiary(){
    if(this.informantIsBene()){
      this.ccfInformant.controls.informantBeneficiaryContactInfo.reset();
      this.ccfInformant.controls.informantBeneficiaryContactInfo.disable();
      this.ccfBene.reset();
      this.ccfBene.controls.beneFirstName.setValue(this.ccfInformant.value.informantFirstName);
      this.ccfBene.controls.beneLastName.setValue(this.ccfInformant.value.informantLastName);
      this.ccfBene.controls.benePhone.setValue(this.ccfInformant.value.informantPhone);
      this.ccfBene.enable();
    }else{
      if(this.ccfInformant.value.informantFirstName === this.ccfBene.value.beneFirstName &&
        this.ccfInformant.value.informantLastName === this.ccfBene.value.beneLastName &&
        this.ccfInformant.value.informantPhone === this.ccfBene.value.benePhone){
          this.ccfBene.reset();
        }
      this.ccfInformant.controls.informantBeneficiaryContactInfo.enable();
    }
  }

  updateBeneFormFormControls_beneCountry(form : FormGroup){
    if(this.isBeneFormCountryOther(form) === true){
      form.controls.beneOtherCountryName.enable();
    }else{
      form.controls.beneOtherCountryName.disable();
    }

    if(this.isBeneFormCountryNotUsa(form) === true){
      form.controls.beneState.disable();
    }else{
      form.controls.beneState.enable();
    }
  }
}
