import { enableProdMode, APP_INITIALIZER, importProvidersFrom } from '@angular/core';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { IConfig, provideEnvironmentNgxMask } from 'ngx-mask';
import { withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { routes } from './app/app-routing.module';
import { bootstrapApplication } from '@angular/platform-browser';
import { FeatureFlagsService } from './app/services/feature-flags.service';
import { provideRouter } from '@angular/router';

export function loadFeatureFlags(ff: FeatureFlagsService) {
  return () => ff.getFeatureFlags();
}

const maskConfig: Partial<IConfig> = {
  validation: true,
};

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(),
        {
            provide: APP_INITIALIZER,
            useFactory: loadFeatureFlags,
            deps: [FeatureFlagsService],
            multi: true
        },
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi()),
        provideEnvironmentNgxMask(maskConfig),
        provideRouter(routes)
    ]
})
  .catch(err => console.error(err));
