import { Injectable } from '@angular/core';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  recaptchaSiteKey = environment.recaptcha.recaptchaSiteKey;
  fileUploadrecaptchaSiteKey = environment.recaptcha.fileUploadrecaptchaSiteKey;
  isClientMobile:boolean = window.innerWidth > 500 ? false : true;

  constructor() { }
}
