import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RecaptchaComponent } from '@ds/claims-portal-ui-components';
import { ConfigService } from 'src/app/services/config.service';
import { ContactInfoService } from 'src/app/services/contact-info.service';
import { FeatureFlagsService } from 'src/app/services/feature-flags.service';
import { FormService } from 'src/app/services/form.service';
import { GuaranteedDeliveryService } from 'src/app/services/guaranteed-delivery.service';
import { RouteToService } from 'src/app/services/route-to.service';
import { FormGroupAdditionalBeneficiariesComponent } from '../form-group-additional-beneficiaries/form-group-additional-beneficiaries.component';
import { FormGroupBeneficiaryComponent } from '../form-group-beneficiary/form-group-beneficiary.component';
import { MatButtonModule } from '@angular/material/button';
import { FormLifeInformantComponent } from '../form-group-informant/form-group-informant.component';
import { FormLifeInsuredComponent } from '../form-group-insured/form-group-insured.component';

@Component({
  selector: 'app-form-group',
  templateUrl: './form-group.component.html',
  styleUrls: ['./form-group.component.scss'],
  standalone: true,
  imports: [RecaptchaComponent, NgIf, FormLifeInsuredComponent, FormLifeInformantComponent, FormGroupBeneficiaryComponent, FormGroupAdditionalBeneficiariesComponent, MatButtonModule]

})
export class FormGroupComponent implements OnInit {

  onSubmit(){
    this.formService.submittingClaim = true;
    if(this.featureFlagsService.featureFlags.consumerClaimFormRecaptchaRequired && this.formService.ccfRecaptcha.invalid === true){
      this.formService.ccfRecaptcha.controls.recaptcha.markAsTouched();
      this.formService.submittingClaim = false;
    }else{
      this.guaranteedDeliveryService.submitClaim(this.formService.consumerClaimForm, this.formService.ccfRecaptcha.controls.recaptcha.value).subscribe(
        (data) => {
        },
        (err) => {
          this.formService.submittingClaim = false;
          this.formService.claimSubmitError = true;
        },
        () => {
          this.formService.submittingClaim = false;
          this.formService.claimSubmitSuccess = true;
          this.formService.claimSubmitError = false;
          this.routeToService.claimSubmitSuccess();
        }
      );
    }
    
  }

  constructor(
    public formService: FormService,
    public contactInfoService: ContactInfoService,
    public configService: ConfigService,
    private guaranteedDeliveryService: GuaranteedDeliveryService,
    private routeToService: RouteToService,
    private featureFlagsService: FeatureFlagsService
  ) { }

  ngOnInit(): void {
  }

}
