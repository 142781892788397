import { Injectable } from '@angular/core';

import { FormService } from '../services/form.service';
import { RouteToService } from '../services/route-to.service';

@Injectable({
  providedIn: 'root'
})
export class ClaimSubmitSuccessGuard  {

  constructor(
    private formService: FormService,
    private routeToService: RouteToService
  ) { }

  canActivate() {
    if (this.formService.claimSubmitSuccess == false){
      this.routeToService.home();
      return false;
    }
    return true;
  }
  
}
