import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FeatureFlagsService } from 'src/app/services/feature-flags.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [NgIf]
})
export class FooterComponent implements OnInit {

  sbtDayOneEnabled:boolean= this.featureFlagsService.featureFlags.sbtDayOne;

  constructor(
    private featureFlagsService: FeatureFlagsService

    ) { }

  ngOnInit(): void {
  }
  copyrightYear = new Date().getFullYear();
}
