// This environment is used for "CCP WebApp - DEBUG" release. We use this to debug on the server.
// Property values with #{{}}# gets replaced via Azure DevOps release variables

import { IEnvApiConnect } from "src/app/interfaces/IEnvApiConnect";

export class onPremAPIC implements IEnvApiConnect {
    apiBasePath = 'https://#{{ON_PREM_API_BASE_PATH}}#';
    clientID = '#{{ON_PREM_API_CLIENT_ID}}#';
}

export class cloudAPIC implements IEnvApiConnect {
    apiBasePath = 'https://#{{CLOUD_API_BASE_PATH}}#';
    clientID = '#{{CLOUD_API_CLIENT_ID}}#';
    apiVersion = '#{{CLOUD_API_VERSION}}#';
}

export class recaptcha {
    recaptchaSiteKey = '#{{RECAPTCHA_SITEKEY}}#';
    fileUploadrecaptchaSiteKey='#{{FILEUPLOAD_RECAPTCHA_SITEKEY}}#';
}

export const environment = {
  production: false,
  onPremAPIC: new onPremAPIC(),
  cloudAPIC: new cloudAPIC(),
  recaptcha: new recaptcha()
};
