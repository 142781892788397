import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})

export class UrlRedirectService {
  constructor(
    public router: Router
    ) { }

  redirect(){
    var baseUrl = window.location.href
    if (baseUrl.includes('myclaimdemo.trustage.com')){
      window.location.href = 'https://consumerclaim-demo.trustagedemo.com' + this.router.url
    }
    else if (baseUrl.includes('myclaimdvlp.trustage.com')){
      window.location.href = 'https://consumerclaim-dvlp.trustagedemo.com' + this.router.url
    }
    else if (baseUrl.includes('myclaim.trustage.com')){
      window.location.href = 'https://consumerclaim.trustage.com' + this.router.url
    }
  }
}
