/** Once the string array order it set for each property, do not move them!!
 *  There is logic built based on the order. Please consult a dev beforehand if you must move the order!
 * */
import { Injectable } from '@angular/core';
import { ClaimType } from '../enumerations/enum-claim-type'
import { LossType } from '../enumerations/enum-loss-type'
import { MannerOfDeath } from '../enumerations/enum-manner-of-death'
import { IllnessType } from '../enumerations/enum-illness-type'

@Injectable({
    providedIn: 'root'
  })
export class DropDownOptions{
    
    constructor(claimType?:ClaimType, lossType?:LossType){
        this.mannerOfDeathOrder(claimType, lossType);
        this.accidentTypeList(claimType, lossType);
    }

    claimType: string[] = [
        ClaimType.Life,
        ClaimType.AccidentalDeathAndDismemberment
    ]

    lossType: string[] = [
        LossType.AccidentalDeath,
        LossType.AccidentalDismemberment,
        LossType.AccidentalHospitalization,
        LossType.CriticalIllness,
        LossType.NaturalDeath
    ]

    gender: string[] = [
        'Male',
        'Female',
        'No response'
    ]

    maritalStatus: string[] = [
        'Married',
        'Divorced',
        'Widowed',
        'Single - Never Married',
        'Unknown'
    ]
    causeOfDeath: string[] = [
        'Cancer',
        'Heart Attack',
        'Stroke/Cerebrovascular Accident',
        'Other Natural Causes',
        'Unknown'
    ]

    mannerOfDeath: string[] = [
        MannerOfDeath.Natural,
        MannerOfDeath.Suicide,
        MannerOfDeath.Homicide,
        MannerOfDeath.Accidental,
        MannerOfDeath.Unknown
    ]

    relationshipToPrimaryInsuredOnCertificate: string[] = [
        'Spouse',
        'Child',
        'Step Child',
        'Unknown',
    ]

    accidentType: string[] = [
        'Aviation Accident',
        'Choking',
        'Drowning',
        'Drug Overdose',
        'Fall',
        'Firearms',
        'Motor Vehicle Accident',
        'Other',
        'Unknown'
    ]


    illnessType: string[] = [
        IllnessType.HeartAttack,
        IllnessType.Stroke,
        IllnessType.Cancer,
        IllnessType.Other
    ]

    yesNo: string[] = [
        'Yes',
        'No'
    ]

    /** The order for Manner Of Death options are different between Life and AD&D Accidentdal Death claims. 
        These are the only two that have this question (as os 5/24/2022) **/
    private mannerOfDeathOrder(claimType:ClaimType, lossType:LossType){
        if(claimType === ClaimType.Life){
            this.mannerOfDeath = [
                MannerOfDeath.Natural,
                MannerOfDeath.Suicide,
                MannerOfDeath.Homicide,
                MannerOfDeath.Accidental,
                MannerOfDeath.Unknown
            ]
        }else if(claimType === ClaimType.AccidentalDeathAndDismemberment && lossType === LossType.AccidentalDeath){
            this.mannerOfDeath = [
                MannerOfDeath.Accidental,
                MannerOfDeath.Suicide,
                MannerOfDeath.Homicide,
                MannerOfDeath.Unknown,
                MannerOfDeath.Natural
            ]
        }
    }

    private accidentTypeList(claimType:ClaimType, lossType:LossType){
        if(claimType === ClaimType.AccidentalDeathAndDismemberment && lossType === LossType.AccidentalDeath){
            this.accidentType = [
                'Aviation Accident',
                'Choking',
                'Drowning',
                'Drug Overdose',
                'Fall',
                'Firearms',
                'Motor Vehicle Accident',
                'Other',
                'Unknown'
            ]
        }else if(claimType === ClaimType.AccidentalDeathAndDismemberment && lossType === LossType.AccidentalHospitalization){
            this.accidentType = [
                'Motor Vehicle Accident',
                'Fall',
                'Other'
            ]
        }
    }
}