import { IInformant } from "../interfaces/IInformant";

export class Informant implements IInformant{
    "isBeneficiary": string; // Yes/No/Unsure
    "firstName": string;
    "lastName": string;
    "relationshipWithDeceased": string;
    "phone": string; // 999-999-9999

    constructor(){
        this.firstName = '';
        this.isBeneficiary = '';
        this.lastName = '';
        this.phone = '';
        this.relationshipWithDeceased = '';
    }
}