
<div *ngIf="formService.isCriticalIllnessLoss()" class="alert alert-warning d-flex gap-3 align-items-center" role="alert">
    <span class="material-icons display-3">warning_amber</span>    
    <div class="vr"></div>
    <div [innerHTML]="formService.errMsg.criticalIllnessWarning"></div>
</div>
<ds-input-dropdownlist
    *ngIf="formService.claimTypeNotSet() || formService.isInsuredStep() || (formService.isADDClaim() && formService.lossTypeNotSet())"
    [form]="formService.ccfInsured"
    formControlNameInput="claimType"
    [options]="formService.options.claimType"
    matFormFieldAppearance="outline"
    matFormFieldClass="input-width-max"
    label="{{formService.lbl.claimType}}" 
    divClass="col-md-5"
    labelClass="col-md-3"
    errorMessage="{{formService.errMsg.claimType}}"
    [isRequired]="true"
    (selectionChange)="updateClaimSteps()"
    ariaLabelInput="{{formService.lbl.claimType}}">
</ds-input-dropdownlist>

<ds-input-dropdownlist
    *ngIf="(formService.isADDClaim() && formService.lossTypeNotSet()) || (formService.isADDClaim() && formService.isInsuredStep())"
    [form]="formService.ccfInsured"
    formControlNameInput="lossType"
    [options]="formService.options.lossType"
    matFormFieldAppearance="outline"
    matFormFieldClass="input-width-max"
    label="{{formService.lbl.lossType}}" 
    divClass="col-md-5"
    labelClass="col-md-3"
    errorMessage="{{formService.errMsg.lossType}}"
    [isRequired]="true"
    (selectionChange)="updateClaimSteps()"
    ariaLabelInput="{{formService.lbl.lossType}}">
</ds-input-dropdownlist>

<div class="row padding-bottom-15px" *ngIf="(formService.isADDClaim() && formService.lossTypeNotSet()) || (formService.isADDClaim() && formService.isInsuredStep())">
    <div class="col-md-5 offset-md-3">
        <mat-accordion>
            <mat-expansion-panel [expanded]="panelOpenState">
                <mat-expansion-panel-header>
                <mat-panel-title>
                    <b>Loss Type Definitions</b>
                </mat-panel-title>
                </mat-expansion-panel-header>
                <b>Accidental Death</b> - Loss due to death caused by an accidental injury<br><br>

                <b>Accidental Dismemberment</b> - Loss due to a dismemberment directly resulting from an accidental injury<br><br>

                <b>Accidental Hospitalization</b> - Loss due to an accidental injury requiring inpatient hospitalization for a period of 8 or more consecutive days<br><br>

                <b>Cancer, Heart Attack, or Stroke Rider</b> - Refers to the diagnosis by a physician of either cancer, heart attack, or a stroke.<br><br>                                                  

                <b>Natural Death</b> - Loss generally occurring from age, bodily disease or illness and not the direct result of an injury from an accident
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>

<span *ngIf="formService.isSummaryStep()">
    <br>
    <h3>Please review the information below before submitting your claim</h3>
    <br>
    <mat-divider></mat-divider>
    <br><br>
</span>
<p class="right-align required" id="denotes-required">* = denotes required fields</p>

<app-form-group></app-form-group>

<span *ngIf="formService.isSummaryStep()">
    <br><br>
    <p>
        <br><br>
        <app-tax-language *ngIf="formService.informantIsBene() || formService.isInformantPrimaryInsured()"></app-tax-language>
        <br><br>
        <app-fraud-language></app-fraud-language>
    </p>
    <br>
    <mat-divider></mat-divider>
    <br>
</span>
