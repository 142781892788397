import { IDeath } from "../interfaces/IDeath";

export class Death implements IDeath{
    "dateOfDeath": string; // MM/DD/YYYY
    "mannerOfDeath": string;
    "funeralHomeName": string;
    "funeralHomeCity": string;
    "funeralHomeState": string;
    "linkToObituary": string;

    constructor(){
        this.dateOfDeath = '';
        this.funeralHomeCity = '';
        this.funeralHomeName = '';
        this.funeralHomeState = '';
        this.linkToObituary = '';
        this.mannerOfDeath = '';
    }
}