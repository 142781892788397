<form [formGroup]="fileUploadService.fileUploadFormGroup" #fileUploadForm="ngForm" aria-label="File upload form" (ngSubmit)="onSubmit(fileUploadService.fileUploadFormGroup)">
  <h4>Please fill out the following information to support your claim</h4>
  <br><br>
  <ds-input-field-text
    [form]="fileUploadService.fileUploadFormGroup" 
    formControlNameInput="claimNumber"
    inputType="text"
    matFormFieldAppearance="outline"
    matFormFieldClass="input-width-max"
    errorMessage="{{fileUploadService.labels.claimNumber}}{{fileUploadService.errMsg.required}}"
    label="{{fileUploadService.labels.claimNumber}}"
    divClass="col-md-5 padding-bottom-15px"
    [isRequired]="true"
    labelClass="col-md-3 padding-bottom-15px"
    ariaLabelInput="{{fileUploadService.labels.insured}}{{fileUploadService.labels.claimNumber}}">
  </ds-input-field-text>

  <ds-file-upload
    [form]="fileUploadService.fileUploadFormGroup" 
    formControlNameInput="file"
    label="{{fileUploadService.labels.uploadDocs}}" 
    divClass="col-md-6 padding-bottom-15px"
    labelClass="col-md-3 padding-bottom-15px"
    [isRequired]="true"
    errorMessageRequired="{{errorMessageRequired()}}"
    errorMessageAlert="{{errorMessageAlert}}"
    [maxFileSizeLimitMB]="sizeLimitMB"
    inputAccept="{{allowedFileTypes}}"
    [showErrorAlert]="showErrorAlert">
  </ds-file-upload>

  <ds-recaptcha  
    [form]="fileUploadService.fileUploadRecaptcha"
    formControlNameInput="recaptcha"
    siteKey="{{configService.fileUploadrecaptchaSiteKey}}"
    divClass="col-md-5 padding-bottom-15px"
    labelClass="col-md-3 padding-bottom-15px"
    [showErrorMessage]="showRecaptchaErrorMessage()"
    errorMessage="{{fileUploadService.errMsg.requiredGeneric}}"
    ariaLabelInput="{{fileUploadService.labels.recaptcha}}">
  </ds-recaptcha>

  <div class="row">
    <div class="col-md-6">
    </div>
    <div class="col-md-6 right-align">
      <button type="submit" [disabled]="(fileUploadForm.submitted && fileUploadService.isFileUploadFormInvalid()) || fileUploadService.uploadingFile" mat-flat-button color="success">Submit</button>
      <br><br>
      <p *ngIf="fileUploadService.uploadingFile">Uploading document...  <span class="spinner-border" role="status"></span></p>
    </div>
    <br><br>
    <p *ngIf="fileUploadForm.submitted && fileUploadService.fileUploadFormGroup.invalid" class="alert alert-danger">
      One or more fields are invalid. Please correct any invalid field(s) and try again.
    </p>
    <p *ngIf="fileUploadService.fileSubmitError" class="alert alert-danger">
      {{fileUploadService.getErrorMessage()}}
    </p>
  </div>
  
</form>
