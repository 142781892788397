import { Injectable } from '@angular/core';
import { FileUploadForm } from '../models/file-upload-form';
import { Labels } from '../models/labels';
import { ErrorMessages } from '../models/error-messages';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  labels:Labels = new Labels();
  errMsg = new ErrorMessages();
  fileSubmitSuccess: boolean = false;
  fileSubmitError: boolean = false;
  statusCode:String = "412";
  fileUploadForm:FileUploadForm = new FileUploadForm();
  claimNumber:String = "";
  uploadingFile: boolean=false;

  constructor() { }

  get fileUploadFormGroup(){
    return this.fileUploadForm.formGroupFileUpload;
  }
  get fileUploadRecaptcha(){
    return this.fileUploadForm.formGroupRecaptcha;
  }

  isRecaptchaTouched(){
    return this.fileUploadRecaptcha.controls.recaptcha.touched;
  }

  isFileUploadFormInvalid():boolean{
    let isInvalid: boolean = false;
    if(this.fileUploadFormGroup.invalid === true){
      isInvalid = true;
    } else if(this.isRecaptchaTouched() && this.fileUploadRecaptcha.invalid === true){
      isInvalid = true;
    }
    return isInvalid;
  }

  getErrorMessage(){
    if(this.statusCode=="412"){
      return "The claim number you entered is not a valid claim number.  Please check the claim number and try again.";
    }
    return "An error occurred while uploading your document. Please try again.";
  }
}