import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { InputFieldTextComponent, InputFieldYesnounsureComponent } from '@ds/claims-portal-ui-components';
import { FormService } from 'src/app/services/form.service';

@Component({
  selector: 'app-form-group-informant',
  templateUrl: './form-group-informant.component.html',
  styleUrls: ['./form-group-informant.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule,InputFieldYesnounsureComponent, InputFieldTextComponent, NgIf, MatButtonModule]
})
export class FormLifeInformantComponent implements OnInit {

  constructor(
    public formService: FormService
  ) { }

  ngOnInit(): void {
    if(this.formService.isSummaryStep()){
      this.formService.ccfInformant.markAllAsTouched();
    }
  }

}
