import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RoutePath } from '../models/route';

@Injectable({
  providedIn: 'root'
})
export class RouteToService {

  routePath = RoutePath

  claimSubmitSuccess(){
    this.router.navigate([this.routePath.claimSubmitSuccess]);
  }

  home(){
    this.router.navigate([this.routePath.home]);
  }

  claimForm(){
    this.router.navigate([this.routePath.claimForm]);
  }

  genericError(){
    this.router.navigate([this.routePath.error]);
  }

  fileUploadHome(){
    this.router.navigate([this.routePath.fileUpload]);
  }

  fileUploadSuccess(){
    this.router.navigate([this.routePath.fileUploadSuccess]);
  }

  constructor(
    private router: Router
  ) { }
}
