import { Injectable } from "@angular/core";
import { ClaimType } from "../enumerations/enum-claim-type";
import { LossType } from "../enumerations/enum-loss-type";

@Injectable({
    providedIn: 'root'
  })
export class Labels{
    firstName = 'First Name';
    lastName = 'Last Name';
    otherName = 'Other Name';
    address = 'Address';
    phone = 'Phone';
    country = 'Country';
    countryOther = "Other Country Name"
    city = 'City';
    state = 'State';
    zipCode = 'Zip Code';
    birthDate = 'Birth Date';
    ssn = 'SSN';
    gender = 'Gender';
    email = 'Email';
    last4SSN = 'Last 4 SSN';
    maritalStatusDeath = 'Marital status at time of death';
    maritalStatusLoss = 'Marital status at Time of Loss';
    dateOfDeath = 'Date of death';
    dateOfDismemberment = 'Date of Dismemberment';
    mannerOfDeath = 'Manner of death';
    covid19Death = 'Was the death due to COVID-19?';
    accidentDescription = 'Describe the accident';
    specificLoss = 'What is the specific loss?';
    foreignCountryDeath = 'Did the death occur in a foreign country?';
    foreignCountryLoss = 'Did the accident occur in a foreign country?';
    funeralName = 'Funeral Home Name';
    funeralCity = 'Funeral Home City';
    funeralState = 'Funeral Home State';
    obituary = 'Link to Obituary';
    policyNumber = 'Policy Number';
    your = 'Your '; //space for next word
    informantRelationshipToInsured = 'What is your relationship to the insured?';
    informantIsBeneficiary = 'Are you a beneficiary?';
    informantBeneficiaryContactInfo = 'Do you have beneficiary contact information?';
    beneficiary = 'Beneficiary '; //space for next word
    insured = 'Insured '; //space for next word
    informant = 'Informant '; //space for next word
    claimType = 'Claim Type';
    recaptcha = 'recaptcha';
    claimNumber = 'Claim Number';
    uploadDocs = 'Upload Document(s)';
    lossType = 'Loss Type';
    insuredMainHeader = 'Please provide information about the insured';
    continue = "Continue";
    back = "Back";
    yes = "Yes";
    no = "No";
    addlBeneQuestion = "Are there more beneficiaries?"
    deleteAddlBene = "Delete Additional Beneficiary"
    addAddlBene = "Add Additional Beneficiary"
    addlBeneMainHeader = "Please provide information about the additional beneficiaries"
    dateOfAccident = 'Date of Accident';
    certificateNumber = 'Certificate number(s) the deceased may be covered under (if known)';
    isPrimaryInsuredOnCertificate = 'Is the deceased the primary insured/owner on this certificate?';
    areYouPrimaryInsuredOnCertificate = 'Are you the primary insured/owner on this certificate?';
    livingPrimaryInsuredOnCertificate = 'Who is the living primary insured/owner on this certificate? (if known)';
    relationshipToPrimaryInsuredOnCertificate = 'What is the deceased\'s relationship to the primary insured/owner of this certificate?';
    maritalStatusOfPrimaryInsuredAtTimeOfLoss = 'What is the marital status of the primary insured/owner at the time of death?';
    accidentType = 'Type of Accident';
    datesOfHospitalizationAccidentalInjury = 'Dates of hospitalization due to the accidental injury (if applicable)';
    otherHospitalizationDates = 'Please list all other hospitalization dates of 8 or more consecutive days due to the accidental injury';
    causeOfDeath = 'Cause of Death';
    illnessType = 'Type of Illness';
    cancerType = 'Type of Cancer (if known)';
    medicalDiagnosis = 'Medical Diagnosis (if known)';
    diagnosisDate = 'Date of Diagnosis (if known)';
    hasInsuredPassedAway = 'Has the insured diagnosed with the above medical condition passed away?';
    informantEmail = 'Informant Email'; 
    accidentDescriptionPlaceholder = '';
    specificLossPlaceholder = 'Example: amputation of left leg above the knee';
    schemaMapper_informantRelationshipToInsured = 'Informant Relationship to the Insured'; 
    hospitalizationDate = 'Date hospitalized due to accidental injury '//space for next word
    from = ' FROM'//space before for text building
    to = ' TO'//space before for text building
    addHospitalization = '(+) Add Hospitalization Dates'
    removeHospitalization = '(-) Remove Dates'
    additionalDates = 'Additional Dates'
    additionalHospitalizationLimitNote = 'If you have additional hospital stays for your accidental injury, we will gather the dates when we review the claim'
    additional = 'Additional '//space for next word

    constructor(claimType?:ClaimType, lossType?:LossType){
        this.dynamicLabelHelper(claimType, lossType)
    }

    private dynamicLabelHelper(claimType:ClaimType, lossType:LossType){

        switch(claimType) { 
            case ClaimType.Life: { 
                this.otherName = 'Other Name';
                this.insuredMainHeader = 'Please provide information about the deceased';
                this.accidentDescription = 'Describe the accident';
                this.informantRelationshipToInsured = 'What is your relationship to the deceased?';
            break; 
            } 
            case ClaimType.AccidentalDeathAndDismemberment: { 
              switch(lossType) { 
                case LossType.AccidentalDeath: { 
                    this.otherName = 'Other names the deceased may have been known as';
                    this.insuredMainHeader = 'Please provide information about the deceased';
                    this.accidentDescription = 'Please describe the event that led to the insured\'s passing.  Additional details provided may help expedite the claim.'
                    this.obituary = 'Link to Obituary (if available)';
                    this.informantRelationshipToInsured = 'What is your relationship to the deceased?';
                break; 
                } 
                case LossType.AccidentalDismemberment: { 
                    this.insuredMainHeader = 'Please provide information about the insured who sustained a loss from an accident';
                    this.otherName = 'Other names the insured may be known as';
                    this.maritalStatusLoss = 'Marital status at time of dismemberment';
                    this.certificateNumber = 'Certificate number(s) the insured may be covered under (if known)';
                    this.accidentDescription = 'Please describe the details of the accident and injury. Additional details provided may help expedite the claim.';
                    this.accidentDescriptionPlaceholder = 'Example: motor cycle accident; hit deer; injured left leg';
                    this.datesOfHospitalizationAccidentalInjury = 'If hospitalized for 8 or more consecutive days, please provide the dates';
                    this.isPrimaryInsuredOnCertificate = 'Is the insured who was dismembered the primary insured/owner on this certificate?';
                break; 
                }
                case LossType.AccidentalHospitalization: { 
                    this.insuredMainHeader = 'Please provide information about the insured who was hospitalized';
                    this.otherName = 'Other names the insured may be known as';
                    this.maritalStatusLoss = 'Marital status at the time of hospitalization due to the accidental injury';
                    this.accidentDescription = 'Please describe the accident and injury. Additional details provided may help expedite the claim';
                    this.accidentDescriptionPlaceholder = 'Example: fell off a ladder; broke left hip';
                    this.datesOfHospitalizationAccidentalInjury = 'Hospitalization dates of 8 or more consecutive days due to the accidental injury';
                    this.foreignCountryLoss = 'Did the accident occur in a foreign country?';
                    this.certificateNumber = 'Certificate number(s) the insured may be covered under (if known)';
                    this.isPrimaryInsuredOnCertificate = 'Is the insured who was hospitalized the primary insured/owner on this certificate?';
                break; 
                }
                case LossType.CriticalIllness: { 
                    this.insuredMainHeader = 'Please provide information about the INSURED who has sustained a Cancer, Heart Attack, or Stroke diagnosis';
                    this.otherName = 'Other names the insured may be known as';
                    this.maritalStatusLoss = 'Marital Status at Time of Diagnosis';
                    this.certificateNumber = 'Certificate number(s) the insured may be covered under (if known)';
                    this.isPrimaryInsuredOnCertificate = 'Is the Insured who has been diagnosed with the above medical condition the Primary Insured/Owner on this Certificate?';
                    this.livingPrimaryInsuredOnCertificate = 'Who is the primary insured/owner on this certificate?';
                    this.relationshipToPrimaryInsuredOnCertificate = 'What is the insured\'s relationship to the primary insured/owner of this certificate?';
                    this.dateOfDeath = 'Insured\'s Date of Death';
                    this.obituary = 'Link to Obituary (if available)';

                break; 
                } 
                case LossType.NaturalDeath: { 
                    this.otherName = 'Other names the deceased may have been known as';
                    this.insuredMainHeader = 'Please provide information about the deceased';
                    this.obituary = 'Link to Obituary (if available)';
                    this.certificateNumber = 'Certificate number(s) the insured may be covered under (if known)';
                    this.informantRelationshipToInsured = 'What is your relationship to the deceased?';
                break; 
                } 
              } 
            break; 
            } 
        } 

    }

}