import { Routes } from '@angular/router';
import { ClaimSubmitSuccessComponent } from './components/router-pages/claim-submit-success/claim-submit-success.component';
import { LandingPageComponent } from './components/router-pages/landing-page/landing-page.component';
import { ErrorDefaultComponent } from './components/router-pages/error-default/error-default.component';
import { ClaimWorkflowComponent } from './components/reusables/claim-workflow/claim-workflow.component';
import { RoutePath, RouteTitle } from './models/route';
import { ClaimSubmitSuccessGuard } from './guards/claim-submit-success.guard';
import { FileUploadComponent } from './components/router-pages/file-upload/file-upload.component';
import { FileUploadSuccessComponent } from './components/router-pages/file-upload-success/file-upload-success.component';
import { FileUploadSuccessGuard } from './guards/file-upload-success.guard';

const routePath = RoutePath;
const routeTitle = RouteTitle

export const routes: Routes = [
  { path: routePath.home,
    component: LandingPageComponent,
    data: {title: routeTitle.home},
    pathMatch: 'full'
  },
  { path: routePath.claimSubmitSuccess,
    component: ClaimSubmitSuccessComponent,
    canActivate: [ClaimSubmitSuccessGuard],
     data: {title: routeTitle.claimSubmitSuccess},
    pathMatch: 'full'
  },
  { path: routePath.claimForm,
    component: ClaimWorkflowComponent,
    data: {title: routeTitle.claimForm},
    pathMatch: 'full'
  },
  { path: routePath.fileUpload,
    component: FileUploadComponent,
    data: {title: routeTitle.fileUpload},
    pathMatch: 'full'
  },
  { path: routePath.fileUploadSuccess,
    component: FileUploadSuccessComponent,
    canActivate: [FileUploadSuccessGuard],
    data: {title: routeTitle.fileUploadSuccess},
    pathMatch: 'full'
  },
  { path: routePath.error,
    component: ErrorDefaultComponent,
    data: {title: routeTitle.error}
  }
]