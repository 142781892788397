<form [formGroup]="formService.ccfInformant" #form="ngForm" aria-label="Claim form" (ngSubmit)="formService.onSubmit(formService.ccfInformant)">
    <h4>Please enter your contact information</h4>
    <br><br>
    <ds-input-field-text
        [form]="formService.ccfInformant" 
        formControlNameInput="informantFirstName"
        inputType="text"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        errorMessage="{{formService.lbl.your}}{{formService.lbl.firstName}}{{formService.errMsg.required}}"
        label="{{formService.lbl.your}}{{formService.lbl.firstName}}"
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        [isRequired]="true"
        ariaLabelInput="{{formService.lbl.informant}}{{formService.lbl.firstName}}">
    </ds-input-field-text>
    <ds-input-field-text
        [form]="formService.ccfInformant" 
        formControlNameInput="informantLastName"
        inputType="text"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        errorMessage="{{formService.lbl.your}}{{formService.lbl.lastName}}{{formService.errMsg.required}}"
        label="{{formService.lbl.your}}{{formService.lbl.lastName}}"
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        [isRequired]="true"
        ariaLabelInput="{{formService.lbl.informant}}{{formService.lbl.lastName}}">
    </ds-input-field-text>
    <ds-input-field-text
        [form]="formService.ccfInformant" 
        formControlNameInput="informantPhone"
        inputType="tel"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        errorMessage="{{formService.lbl.your}}{{formService.lbl.phone}}{{formService.errMsg.required}}"
        label="{{formService.lbl.your}}{{formService.lbl.phone}}"
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        [isRequired]="true"
        inputMask="{{formService.mask.phone}}"
        ariaLabelInput="{{formService.lbl.informant}}{{formService.lbl.phone}}">
    </ds-input-field-text>
    <ds-input-field-text
        *ngIf="!formService.isLifeClaim() && !formService.isAccidentalDeathLoss()"
        [form]="formService.ccfInformant" 
        formControlNameInput="informantEmail"
        inputType="email"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        label="{{formService.lbl.your}}{{formService.lbl.email}}"
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        ariaLabelInput="{{formService.lbl.informant}}{{formService.lbl.email}}">
    </ds-input-field-text>
    <ds-input-field-text
        [form]="formService.ccfInformant" 
        formControlNameInput="informantRelationshipToInsured"
        inputType="text"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        errorMessage="{{formService.errMsg.requiredGeneric}}"
        label="{{formService.lbl.informantRelationshipToInsured}}"
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        [isRequired]="true"
        ariaLabelInput="{{formService.lbl.informantRelationshipToInsured}}">
    </ds-input-field-text>
    <ds-input-field-yesnounsure 
        *ngIf="formService.isLifeClaim() || (formService.isADDClaim() && formService.isAccidentalDeathLoss())"
        [form]="formService.ccfInformant"
        formControlNameInput="informantIsBeneficiary"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        errorMessage="{{formService.errMsg.requiredGeneric}}"
        label="{{formService.lbl.informantIsBeneficiary}}" 
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        [isRequired]="true"
        (selectionChange)="formService.updateFormGroup_informantIsBeneficiary()"
        ariaLabelInput="{{formService.lbl.informantIsBeneficiary}}"> 
    </ds-input-field-yesnounsure>
    <ds-input-field-yesnounsure 
        *ngIf="formService.isLifeClaim() || (formService.isADDClaim() && formService.isAccidentalDeathLoss())"
        [hidden]="formService.informantIsBene() || formService.ccfInformant.controls.informantIsBeneficiary.pristine"
        [form]="formService.ccfInformant"
        formControlNameInput="informantBeneficiaryContactInfo"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        errorMessage="{{formService.errMsg.requiredGeneric}}"
        label="{{formService.lbl.informantBeneficiaryContactInfo}}" 
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        [isRequired]="true"
        (selectionChange)="formService.updateFormGroup_informantBeneficiaryContactInfo()"
        ariaLabelInput="{{formService.lbl.informantBeneficiaryContactInfo}}"> 
    </ds-input-field-yesnounsure>
    <br><br>
    <div class="row" *ngIf="!formService.isSummaryStep()">
        <div class="col-md-6">
            <button type="button" (click)="formService.back()" mat-flat-button color="danger">Back</button>
        </div>
        <div class="col-md-6 right-align">
            <button type="submit" [disabled]="form.submitted && formService.ccfInformant.invalid" mat-flat-button color="success">Continue</button>
        </div>
        <br><br>
        <p *ngIf="form.submitted && formService.ccfInformant.invalid" class="alert alert-danger">
            One or more fields are invalid. Please correct any invalid field(s) and try again.
        </p>
    </div>
</form>
