import { Injectable } from '@angular/core';
import { Beneficiary } from '../models/beneficiary';
import { ConsumerClaimForm } from '../models/consumer-claim-form';
import { ConsumerClaimProperty } from '../models/consumer-claim-property';
import { ConsumerClaimSchema } from '../models/consumer-claim-schema';
import { Informant } from '../models/informant';
import { Death } from '../models/death';
import { ConsumerClaimDocument } from '../models/document';
import { Insured } from '../models/insured';
import { Predetermined } from '../enumerations/enum-predetermined';
import * as moment from 'moment';
import { MannerOfDeath } from '../enumerations/enum-manner-of-death';
import { LossType } from '../enumerations/enum-loss-type';
import { FormService } from './form.service';
import { FormGroupProperties as fp } from '../models/constants/form-group-properties';
import { FormArray, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class SchemaMapperService {

  
  constructor(
    private formService: FormService
    ) { }


  mapConsumerClaimsSchema(consumerClaimSchema:ConsumerClaimSchema, consumerClaimForm:ConsumerClaimForm):ConsumerClaimSchema{

    //set '' for all disabled formControl's. Some fields disable/enable based on user input. This will ensure disabled formControl's value don't make it through.
    for (let formGroupProperty in consumerClaimForm){
      Object.keys(consumerClaimForm[formGroupProperty].controls).forEach(key => {
        if(consumerClaimForm[formGroupProperty].get(key).disabled){
          consumerClaimForm[formGroupProperty].get(key).patchValue('');
        }
      });
    }

    consumerClaimSchema.claim.claimType = consumerClaimForm.formGroupInsured.value.claimType;
    consumerClaimSchema.claim.lossType = consumerClaimForm.formGroupInsured.value.lossType === LossType.NotSet ? '': consumerClaimForm.formGroupInsured.value.lossType;
    consumerClaimSchema.claim.insured = this.mapInsured(consumerClaimSchema.claim.insured, consumerClaimForm);
    consumerClaimSchema.claim.contact = this.mapInformant(consumerClaimSchema.claim.contact, consumerClaimForm);
    consumerClaimSchema.claim.death = this.mapDeath(consumerClaimSchema.claim.death, consumerClaimForm);
    consumerClaimSchema.claim.beneficiary = new Array<Beneficiary>();
    consumerClaimSchema.claim.beneficiary[0]= this.mapBeneficiary(new Beneficiary(), consumerClaimForm);
    this.mapAdditionalBenes(consumerClaimSchema.claim.beneficiary,consumerClaimForm.formGroupAddlBenes);
    consumerClaimSchema.claim.consumerClaimProperties = this.mapConsumerClaimProperties(consumerClaimSchema.claim.consumerClaimProperties, consumerClaimForm);
    consumerClaimSchema.documents = this.mapDocuments(consumerClaimSchema.documents, consumerClaimForm);

    return consumerClaimSchema;
  }

  mapInsured(objInsured:Insured, objConsumerClaimForm:ConsumerClaimForm):Insured{

    objInsured.address = objConsumerClaimForm.formGroupInsured.value.insuredAddress;
    objInsured.birthDate = this.dateFormat(objConsumerClaimForm.formGroupInsured.value.insuredBirthDate);
    objInsured.city = objConsumerClaimForm.formGroupInsured.value.insuredCity
    objInsured.firstName = objConsumerClaimForm.formGroupInsured.value.insuredFirstName
    objInsured.last4SSN = this.isEmptyValue(objConsumerClaimForm.formGroupInsured.value.insuredLast4SSN) ? 0 : objConsumerClaimForm.formGroupInsured.value.insuredLast4SSN;
    objInsured.lastName = objConsumerClaimForm.formGroupInsured.value.insuredLastName
    objInsured.maritalStatusOnDeath = objConsumerClaimForm.formGroupInsured.value.insuredMaritalStatusAtTimeOfDeath
    objInsured.otherName = objConsumerClaimForm.formGroupInsured.value.insuredOtherName
    objInsured.policyNumber = objConsumerClaimForm.formGroupInsured.value.insuredPolicyNumber
    objInsured.state = objConsumerClaimForm.formGroupInsured.value.insuredState
    objInsured.zipCode = objConsumerClaimForm.formGroupInsured.value.insuredZipCode

    return objInsured;
  }

  mapInformant(objInformant:Informant, objConsumerClaimForm:ConsumerClaimForm):Informant{
    objInformant.firstName = objConsumerClaimForm.formGroupInformant.value.informantFirstName
    objInformant.isBeneficiary = objConsumerClaimForm.formGroupInformant.value.informantIsBeneficiary
    objInformant.lastName = objConsumerClaimForm.formGroupInformant.value.informantLastName
    objInformant.phone = objConsumerClaimForm.formGroupInformant.value.informantPhone
    if(this.formService.isLifeClaim() || (this.formService.isADDClaim() && (this.formService.isAccidentalDeathLoss() || this.formService.isNaturalDeathLoss()))){
      objInformant.relationshipWithDeceased = objConsumerClaimForm.formGroupInformant.value.informantRelationshipToInsured
    }
    

    return objInformant;
  }

  mapDeath(objDeath:Death, objConsumerClaimForm:ConsumerClaimForm):Death{
    objDeath.dateOfDeath = this.dateFormat(objConsumerClaimForm.formGroupInsured.value.insuredDateOfDeath);
    objDeath.funeralHomeCity = objConsumerClaimForm.formGroupInsured.value.insuredFuneralHomeCity
    objDeath.funeralHomeName = objConsumerClaimForm.formGroupInsured.value.insuredFuneralHomeName
    objDeath.funeralHomeState = objConsumerClaimForm.formGroupInsured.value.insuredFuneralHomeState
    objDeath.linkToObituary = this.removeLeadingHttpsFromValue(objConsumerClaimForm.formGroupInsured.value.insuredObituary)
    objDeath.mannerOfDeath = objConsumerClaimForm.formGroupInsured.value.insuredMannerOfDeath

    return objDeath;
  }

  mapBeneficiary(objBeneficiary:Beneficiary, objConsumerClaimForm:ConsumerClaimForm):Beneficiary{
    objBeneficiary.address = objConsumerClaimForm.formGroupBene.value.beneAddress
    objBeneficiary.birthDate = this.dateFormat(objConsumerClaimForm.formGroupBene.value.beneBirthDate);
    objBeneficiary.city = objConsumerClaimForm.formGroupBene.value.beneCity
    objBeneficiary.country = objConsumerClaimForm.formGroupBene.value.beneCountry
    objBeneficiary.email = objConsumerClaimForm.formGroupBene.value.beneEmail
    objBeneficiary.firstName = objConsumerClaimForm.formGroupBene.value.beneFirstName
    objBeneficiary.gender = objConsumerClaimForm.formGroupBene.value.beneGender
    objBeneficiary.lastName = objConsumerClaimForm.formGroupBene.value.beneLastName
    objBeneficiary.otherCountry = objConsumerClaimForm.formGroupBene.value.beneOtherCountryName
    objBeneficiary.phone = objConsumerClaimForm.formGroupBene.value.benePhone
    objBeneficiary.ssnToken = this.isEmptyValue(objConsumerClaimForm.formGroupBene.value.beneSSN) ? '' : objConsumerClaimForm.formGroupBene.value.beneSSN
    objBeneficiary.state = objConsumerClaimForm.formGroupBene.value.beneState
    objBeneficiary.zipCode = objConsumerClaimForm.formGroupBene.value.beneZipCode

    if(objConsumerClaimForm.formGroupInformant.enabled && (objConsumerClaimForm.formGroupInformant.value.informantIsBeneficiary !== Predetermined.Yes && objConsumerClaimForm.formGroupInformant.value.informantBeneficiaryContactInfo !== Predetermined.Yes)){
      objBeneficiary = new Beneficiary();
    }

    return objBeneficiary;
  }

  mapDocuments(documents:ConsumerClaimDocument[], objConsumerClaimForm:ConsumerClaimForm):ConsumerClaimDocument[]{
      //no functionality yet
    return documents;
  }

  removeLeadingHttpsFromValue(value:string):string{
    if(value != null && value.trim() != ''){
      if(value.startsWith('https://')){
        return value.replace('https://', '')
      }
      if(value.startsWith('http://')){
        return value.replace('http://', '')
      }
    }
    return value;
  }

  dateFormat(date){
    return this.isEmptyValue(date) ? '' : moment(date, "MM/DD/YYYY").format('L');
  }

  isEmptyValue(value){
    return value === '' || value === null || value === undefined;
  }
  
  mapConsumerClaimProperties(consumerClaimProperties:ConsumerClaimProperty[], objConsumerClaimForm:ConsumerClaimForm):ConsumerClaimProperty[]{

    consumerClaimProperties = new Array<ConsumerClaimProperty>();

    if(this.formService.isLifeClaim()){
      if(objConsumerClaimForm.formGroupInsured.value.insuredMannerOfDeath === MannerOfDeath.Natural){
        this.consumerClaimPropertiesPush(consumerClaimProperties, 'deathDueToCovid19', 'Was the death due to COVID-19?', objConsumerClaimForm.formGroupInsured.value.insuredWasDeathDueToCovid19);
      }
      if(objConsumerClaimForm.formGroupInsured.value.insuredMannerOfDeath === MannerOfDeath.Accidental){
        this.consumerClaimPropertiesPush(consumerClaimProperties, 'accidentDescription', 'Describe the Accident', objConsumerClaimForm.formGroupInsured.value.insuredAccidentDescription);
      }
      this.consumerClaimPropertiesPush(consumerClaimProperties, 'deathInForeignCountry', 'Did death occur in a foreign country?', objConsumerClaimForm.formGroupInsured.value.insuredDidDeathOccurInForeignCountry);
    }

    if(this.formService.isADDClaim()){

      if(this.formService.isAccidentalDeathLoss()){
        this.consumerClaimPropertiesPush(consumerClaimProperties, 
          fp.insured.accidentDate, 
          this.formService.lbl.dateOfAccident, 
          this.dateFormat(objConsumerClaimForm.formGroupInsured.get(fp.insured.accidentDate).value));
        this.consumerClaimPropertiesPush(consumerClaimProperties, 
          fp.insured.accidentType, 
          this.formService.lbl.accidentType, 
          objConsumerClaimForm.formGroupInsured.get(fp.insured.accidentType).value);
        this.consumerClaimPropertiesPush(consumerClaimProperties,
          fp.insured.insuredDidDeathOccurInForeignCountry,
          this.formService.lbl.foreignCountryDeath,
          objConsumerClaimForm.formGroupInsured.get(fp.insured.insuredDidDeathOccurInForeignCountry).value);
        this.consumerClaimPropertiesPush(consumerClaimProperties, 
          fp.insured.insuredAccidentDescription, 
          this.formService.lbl.accidentDescription, 
          objConsumerClaimForm.formGroupInsured.get(fp.insured.insuredAccidentDescription).value);
        this.consumerClaimPropertiesPush(consumerClaimProperties, 
          fp.insured.hospitalizationFromDate, 
          'Date hospitalized due to accidental injury FROM', 
          this.dateFormat(objConsumerClaimForm.formGroupInsured.get(fp.insured.hospitalizationFromDate).value));
        this.consumerClaimPropertiesPush(consumerClaimProperties, 
          fp.insured.hospitalizationToDate, 
          'Date hospitalized due to accidental injury TO', 
          this.dateFormat(objConsumerClaimForm.formGroupInsured.get(fp.insured.hospitalizationToDate).value));
        this.consumerClaimPropertiesPush(consumerClaimProperties, 
          fp.insured.certificateNumber, 
          this.formService.lbl.certificateNumber, 
          objConsumerClaimForm.formGroupInsured.get(fp.insured.certificateNumber).value);
        this.consumerClaimPropertiesPush(consumerClaimProperties, 
          fp.insured.isPrimaryInsuredOnCertificate, 
          this.formService.lbl.isPrimaryInsuredOnCertificate, 
          objConsumerClaimForm.formGroupInsured.get(fp.insured.isPrimaryInsuredOnCertificate).value);
        this.consumerClaimPropertiesPush(consumerClaimProperties, 
          fp.insured.areYouPrimaryInsuredOnCertificate, 
          this.formService.lbl.areYouPrimaryInsuredOnCertificate, 
          objConsumerClaimForm.formGroupInsured.get(fp.insured.areYouPrimaryInsuredOnCertificate).value);
        this.consumerClaimPropertiesPush(consumerClaimProperties, 
          fp.insured.livingPrimaryInsuredOnCertificate, 
          this.formService.lbl.livingPrimaryInsuredOnCertificate, 
          objConsumerClaimForm.formGroupInsured.get(fp.insured.livingPrimaryInsuredOnCertificate).value);
        this.consumerClaimPropertiesPush(consumerClaimProperties, 
          fp.insured.relationshipToPrimaryInsuredOnCertificate, 
          this.formService.lbl.relationshipToPrimaryInsuredOnCertificate, 
          objConsumerClaimForm.formGroupInsured.get(fp.insured.relationshipToPrimaryInsuredOnCertificate).value);
        this.consumerClaimPropertiesPush(consumerClaimProperties, 
          fp.insured.maritalStatusOfPrimaryInsuredAtTimeOfLoss, 
          this.formService.lbl.maritalStatusOfPrimaryInsuredAtTimeOfLoss, 
          objConsumerClaimForm.formGroupInsured.get(fp.insured.maritalStatusOfPrimaryInsuredAtTimeOfLoss).value);

      }else if(this.formService.isNaturalDeathLoss()){
        this.consumerClaimPropertiesPush(consumerClaimProperties, 
          fp.insured.certificateNumber, 
          this.formService.lbl.certificateNumber, 
          objConsumerClaimForm.formGroupInsured.get(fp.insured.certificateNumber).value);
        this.consumerClaimPropertiesPush(consumerClaimProperties,
          fp.insured.insuredDidDeathOccurInForeignCountry,
          this.formService.lbl.foreignCountryDeath,
          objConsumerClaimForm.formGroupInsured.get(fp.insured.insuredDidDeathOccurInForeignCountry).value);
        this.consumerClaimPropertiesPush(consumerClaimProperties,
          fp.informant.informantEmail,
          this.formService.lbl.informantEmail,
          objConsumerClaimForm.formGroupInformant.get(fp.informant.informantEmail).value);
        this.consumerClaimPropertiesPush(consumerClaimProperties,
          fp.insured.causeOfDeath,
          this.formService.lbl.causeOfDeath,
          objConsumerClaimForm.formGroupInsured.get(fp.insured.causeOfDeath).value);

      }else if(this.formService.isCriticalIllnessLoss()){
        this.consumerClaimPropertiesPush(consumerClaimProperties,
          fp.insured.insuredMaritalStatusAtTimeOfLoss,
          this.formService.lbl.maritalStatusLoss,
          objConsumerClaimForm.formGroupInsured.get(fp.insured.insuredMaritalStatusAtTimeOfLoss).value);
        this.consumerClaimPropertiesPush(consumerClaimProperties,
          fp.insured.illnessType,
          this.formService.lbl.illnessType,
          objConsumerClaimForm.formGroupInsured.get(fp.insured.illnessType).value);
        this.consumerClaimPropertiesPush(consumerClaimProperties,
          fp.insured.cancerType,
          this.formService.lbl.cancerType,
          objConsumerClaimForm.formGroupInsured.get(fp.insured.cancerType).value);
        this.consumerClaimPropertiesPush(consumerClaimProperties,
          fp.insured.medicalDiagnosis,
          this.formService.lbl.medicalDiagnosis,
          objConsumerClaimForm.formGroupInsured.get(fp.insured.medicalDiagnosis).value);
        this.consumerClaimPropertiesPush(consumerClaimProperties,
          fp.insured.diagnosisDate,
          this.formService.lbl.diagnosisDate,
          this.dateFormat(objConsumerClaimForm.formGroupInsured.get(fp.insured.diagnosisDate).value));
        this.consumerClaimPropertiesPush(consumerClaimProperties,
          fp.insured.hasInsuredPassedAway,
          this.formService.lbl.hasInsuredPassedAway,
          objConsumerClaimForm.formGroupInsured.get(fp.insured.hasInsuredPassedAway).value);
        this.consumerClaimPropertiesPush(consumerClaimProperties, 
          fp.insured.certificateNumber, 
          this.formService.lbl.certificateNumber, 
          objConsumerClaimForm.formGroupInsured.get(fp.insured.certificateNumber).value);
        this.consumerClaimPropertiesPush(consumerClaimProperties, 
          fp.insured.areYouPrimaryInsuredOnCertificate, 
          this.formService.lbl.areYouPrimaryInsuredOnCertificate, 
          objConsumerClaimForm.formGroupInsured.get(fp.insured.areYouPrimaryInsuredOnCertificate).value);
        this.consumerClaimPropertiesPush(consumerClaimProperties, 
          fp.insured.isPrimaryInsuredOnCertificate, 
          this.formService.lbl.isPrimaryInsuredOnCertificate, 
          objConsumerClaimForm.formGroupInsured.get(fp.insured.isPrimaryInsuredOnCertificate).value);
        this.consumerClaimPropertiesPush(consumerClaimProperties, 
          fp.insured.livingPrimaryInsuredOnCertificate, 
          this.formService.lbl.livingPrimaryInsuredOnCertificate, 
          objConsumerClaimForm.formGroupInsured.get(fp.insured.livingPrimaryInsuredOnCertificate).value);
        this.consumerClaimPropertiesPush(consumerClaimProperties, 
          fp.insured.relationshipToPrimaryInsuredOnCertificate, 
          this.formService.lbl.relationshipToPrimaryInsuredOnCertificate, 
          objConsumerClaimForm.formGroupInsured.get(fp.insured.relationshipToPrimaryInsuredOnCertificate).value);
      }else if(this.formService.isAccidentalDismembermentLoss()){
        this.consumerClaimPropertiesPush(consumerClaimProperties,
          fp.insured.insuredMaritalStatusAtTimeOfLoss,
          this.formService.lbl.maritalStatusLoss,
          objConsumerClaimForm.formGroupInsured.get(fp.insured.insuredMaritalStatusAtTimeOfLoss).value);
        this.consumerClaimPropertiesPush(consumerClaimProperties,
          fp.insured.accidentDate,
          this.formService.lbl.dateOfAccident,
          this.dateFormat(objConsumerClaimForm.formGroupInsured.get(fp.insured.accidentDate).value));
        this.consumerClaimPropertiesPush(consumerClaimProperties,
          fp.insured.dismembermentDate,
          this.formService.lbl.dateOfDismemberment,
          this.dateFormat(objConsumerClaimForm.formGroupInsured.get(fp.insured.dismembermentDate).value));
        this.consumerClaimPropertiesPush(consumerClaimProperties,
          fp.insured.insuredAccidentDescription,
          this.formService.lbl.accidentDescription,
          objConsumerClaimForm.formGroupInsured.get(fp.insured.insuredAccidentDescription).value);
        this.consumerClaimPropertiesPush(consumerClaimProperties,
          fp.insured.insuredSpecificLoss,
          this.formService.lbl.specificLoss,
          objConsumerClaimForm.formGroupInsured.get(fp.insured.insuredSpecificLoss).value);
        this.consumerClaimPropertiesPush(consumerClaimProperties,
          fp.insured.hospitalizationFromDate,
          'Date hospitalized FROM',
          this.dateFormat(objConsumerClaimForm.formGroupInsured.get(fp.insured.hospitalizationFromDate).value));
        this.consumerClaimPropertiesPush(consumerClaimProperties,
          fp.insured.hospitalizationToDate,
          'Date hospitalized TO',
          this.dateFormat(objConsumerClaimForm.formGroupInsured.get(fp.insured.hospitalizationToDate).value));
        this.consumerClaimPropertiesPush(consumerClaimProperties,
          fp.insured.didLossOccurInForeignCountry,
          this.formService.lbl.foreignCountryLoss,
          objConsumerClaimForm.formGroupInsured.get(fp.insured.didLossOccurInForeignCountry).value);
        this.consumerClaimPropertiesPush(consumerClaimProperties,
          fp.insured.certificateNumber,
          this.formService.lbl.certificateNumber,
          objConsumerClaimForm.formGroupInsured.get(fp.insured.certificateNumber).value);
        this.consumerClaimPropertiesPush(consumerClaimProperties,
          fp.insured.isPrimaryInsuredOnCertificate,
          this.formService.lbl.isPrimaryInsuredOnCertificate,
          objConsumerClaimForm.formGroupInsured.get(fp.insured.isPrimaryInsuredOnCertificate).value);
        this.consumerClaimPropertiesPush(consumerClaimProperties,
          fp.informant.informantRelationshipToInsured,
          this.formService.lbl.schemaMapper_informantRelationshipToInsured,
          objConsumerClaimForm.formGroupInformant.get(fp.informant.informantRelationshipToInsured).value);
        this.consumerClaimPropertiesPush(consumerClaimProperties,
          fp.informant.informantEmail,
          this.formService.lbl.informantEmail,
          objConsumerClaimForm.formGroupInformant.get(fp.informant.informantEmail).value);
      }else if(this.formService.isAccidentalHospitalizationLoss()){
        this.consumerClaimPropertiesPush(consumerClaimProperties, 
          fp.insured.maritalStatusOfPrimaryInsuredAtTimeOfLoss, 
          this.formService.lbl.maritalStatusLoss, 
          objConsumerClaimForm.formGroupInsured.get(fp.insured.insuredMaritalStatusAtTimeOfLoss).value);

        this.consumerClaimPropertiesPush(consumerClaimProperties, 
          fp.insured.accidentDate, 
          this.formService.lbl.dateOfAccident, 
          this.dateFormat(objConsumerClaimForm.formGroupInsured.get(fp.insured.accidentDate).value));

        this.consumerClaimPropertiesPush(consumerClaimProperties, 
          fp.insured.accidentType, 
          this.formService.lbl.accidentType, 
          objConsumerClaimForm.formGroupInsured.get(fp.insured.accidentType).value);

        this.consumerClaimPropertiesPush(consumerClaimProperties, 
          fp.insured.insuredAccidentDescription, 
          this.formService.lbl.accidentDescription, 
          objConsumerClaimForm.formGroupInsured.get(fp.insured.insuredAccidentDescription).value);

        this.consumerClaimPropertiesPush(consumerClaimProperties, 
          fp.insured.hospitalizationFromDate, 
          this.formService.lbl.hospitalizationDate + this.formService.lbl.from,
          this.dateFormat(objConsumerClaimForm.formGroupInsured.get(fp.insured.hospitalizationFromDate).value));

        this.consumerClaimPropertiesPush(consumerClaimProperties, 
          fp.insured.hospitalizationToDate, 
          this.formService.lbl.hospitalizationDate + this.formService.lbl.to,
          this.dateFormat(objConsumerClaimForm.formGroupInsured.get(fp.insured.hospitalizationToDate).value));
        
        let additionalDates :FormArray =  objConsumerClaimForm.formGroupInsured.get(fp.insured.additionalHospitalizationDates) as FormArray
        for(let index : number = 0; index< additionalDates.controls.length;index++){ 
          let additionalHospitalization : FormGroup= additionalDates.controls[index] as FormGroup 
          this.consumerClaimPropertiesPush(consumerClaimProperties, 
            fp.additionalHospitalizationDate.startDate(index+1), 
            this.formService.lbl.additional + this.formService.lbl.hospitalizationDate + ' ('+(index+1) +')' + this.formService.lbl.from,
            this.dateFormat(additionalHospitalization.get(fp.insured.hospitalizationFromDate).value));
  
          this.consumerClaimPropertiesPush(consumerClaimProperties, 
            fp.additionalHospitalizationDate.endDate(index+1), 
            this.formService.lbl.additional + this.formService.lbl.hospitalizationDate + ' ('+(index+1) +')' + this.formService.lbl.to,
            this.dateFormat(additionalHospitalization.get(fp.insured.hospitalizationToDate).value));
        }

        this.consumerClaimPropertiesPush(consumerClaimProperties,
          fp.insured.didLossOccurInForeignCountry,
          this.formService.lbl.foreignCountryLoss,
          objConsumerClaimForm.formGroupInsured.get(fp.insured.didLossOccurInForeignCountry).value);

        this.consumerClaimPropertiesPush(consumerClaimProperties, 
          fp.insured.certificateNumber, 
          this.formService.lbl.certificateNumber, 
          objConsumerClaimForm.formGroupInsured.get(fp.insured.certificateNumber).value);

        this.consumerClaimPropertiesPush(consumerClaimProperties, 
          fp.insured.isPrimaryInsuredOnCertificate, 
          this.formService.lbl.isPrimaryInsuredOnCertificate, 
          objConsumerClaimForm.formGroupInsured.get(fp.insured.isPrimaryInsuredOnCertificate).value);

        this.consumerClaimPropertiesPush(consumerClaimProperties,
          fp.informant.informantRelationshipToInsured,
          this.formService.lbl.schemaMapper_informantRelationshipToInsured,
          objConsumerClaimForm.formGroupInformant.get(fp.informant.informantRelationshipToInsured).value);
  

        this.consumerClaimPropertiesPush(consumerClaimProperties,
          fp.informant.informantEmail,
          this.formService.lbl.informantEmail,
          objConsumerClaimForm.formGroupInformant.get(fp.informant.informantEmail).value); 
      }
    }
    return consumerClaimProperties;
  }

  /*
    TODO: mapAdditionalBenes to be depricated after form work to have a Beneficiary[] form is completed.
  */
  mapAdditionalBenes(beneficiaries : Array<Beneficiary>,additionalBenes : FormArray){
    for(let index : number = 0; index<additionalBenes.controls.length;index++){
      let beneForm : FormGroup= additionalBenes.controls[index] as FormGroup;
      let bene : Beneficiary= new Beneficiary();
      bene.firstName = beneForm.get(fp.bene.beneFirstName).value;
      bene.lastName = beneForm.get(fp.bene.beneLastName).value;
      bene.city = beneForm.get(fp.bene.beneCity).value;
      bene.address = beneForm.get(fp.bene.beneAddress).value;
      bene.country = beneForm.get(fp.bene.beneCountry).value;
      bene.email = beneForm.get(fp.bene.beneEmail).value;
      bene.gender = beneForm.get(fp.bene.beneGender).value;
      bene.otherCountry = beneForm.get(fp.bene.beneOtherCountryName).value;
      bene.phone = beneForm.get(fp.bene.benePhone).value;
      bene.ssnToken = beneForm.get(fp.bene.beneSSN).value;
      bene.state = beneForm.get(fp.bene.beneState).value;
      bene.zipCode = beneForm.get(fp.bene.beneZipCode).value;
      bene.birthDate = beneForm.get(fp.bene.beneBirthDate).value;
      beneficiaries.push(bene);
    }
  }

  consumerClaimPropertiesPush(consumerClaimProperties:ConsumerClaimProperty[], key:string, name:string, value:string){
    let tempConsumerClaimProperty = new ConsumerClaimProperty();
    tempConsumerClaimProperty.key = key;
    tempConsumerClaimProperty.name = name;
    tempConsumerClaimProperty.value = value;
    consumerClaimProperties.push(tempConsumerClaimProperty);
  }

}
