<div class="container-fluid header-padding-bottom">
    <div class="row">
        <div class="col-md-12 cmg-body">
            <div style="padding: 13px">
                <a target="_blank" href="https://www.TruStage.com">
                    <img src="../../../../assets/cmfg/Trustage-Logo-SB.svg" height="38" width="182">
                </a>
            </div>
        </div>
    </div>
</div>