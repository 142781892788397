<form [formGroup]="formService.ccfInsured" #form="ngForm" aria-label="Claim form" (ngSubmit)="formService.onSubmit(formService.ccfInsured)">
    <h4>{{formService.lbl.insuredMainHeader}}</h4>
    <br><br>
    <ds-input-field-text
        [form]="formService.ccfInsured" 
        formControlNameInput="insuredFirstName"
        inputType="text"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        errorMessage="{{formService.lbl.firstName}}{{formService.errMsg.required}}"
        label="{{formService.lbl.firstName}}"
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        [isRequired]="true"
        ariaLabelInput="{{formService.lbl.insured}}{{formService.lbl.firstName}}">
    </ds-input-field-text>
    <ds-input-field-text
        [form]="formService.ccfInsured" 
        formControlNameInput="insuredLastName"
        inputType="text"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        errorMessage="{{formService.lbl.lastName}}{{formService.errMsg.required}}"
        label="{{formService.lbl.lastName}}"
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        [isRequired]="true"
        ariaLabelInput="{{formService.lbl.insured}}{{formService.lbl.lastName}}">
    </ds-input-field-text>
    <ds-input-field-text
        [form]="formService.ccfInsured" 
        formControlNameInput="insuredOtherName"
        inputType="text"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        label="{{formService.lbl.otherName}}"
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        ariaLabelInput="{{formService.lbl.insured}}{{formService.lbl.otherName}}">
    </ds-input-field-text>
    <ds-input-field-text
        [form]="formService.ccfInsured" 
        formControlNameInput="insuredAddress"
        inputType="text"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        errorMessage="{{formService.lbl.address}}{{formService.errMsg.required}}"
        label="{{formService.lbl.address}}"
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        [isRequired]="true"
        ariaLabelInput="{{formService.lbl.insured}}{{formService.lbl.address}}">
    </ds-input-field-text>
    <ds-input-field-text
        [form]="formService.ccfInsured" 
        formControlNameInput="insuredCity"
        inputType="text"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        errorMessage="{{formService.lbl.city}}{{formService.errMsg.required}}"
        label="{{formService.lbl.city}}"
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        [isRequired]="true"
        ariaLabelInput="{{formService.lbl.insured}}{{formService.lbl.city}}">
    </ds-input-field-text>
    <ds-state-selector
        [form]="formService.ccfInsured"
        formControlNameInput="insuredState"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        label="{{formService.lbl.state}}" 
        errorMessage="{{formService.lbl.state}}{{formService.errMsg.required}}"
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        [isRequired]="true"
        ariaLabelInput="{{formService.lbl.insured}}{{formService.lbl.state}}">
    </ds-state-selector>
    <ds-input-field-text
        [form]="formService.ccfInsured" 
        formControlNameInput="insuredZipCode"
        inputType="tel"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        errorMessage="{{formService.lbl.zipCode}}{{formService.errMsg.required}}"
        label="{{formService.lbl.zipCode}}"
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        [isRequired]="true"
        inputMask="{{formService.mask.zipCode}}"
        ariaLabelInput="{{formService.lbl.insured}}{{formService.lbl.zipCode}}">
    </ds-input-field-text>
    <ds-input-field-date
        [form]="formService.ccfInsured" 
        formControlNameInput="insuredBirthDate"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        errorMessage="{{formService.lbl.birthDate}}{{formService.errMsg.required}}"
        label="{{formService.lbl.birthDate}}"
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        [isTouchUi]=configService.isClientMobile
        [isRequired]="true"
        placeholderText="{{formService.placeholder.dateFormat}}"
        ariaLabelInput="{{formService.lbl.insured}}{{formService.lbl.birthDate}}">
    </ds-input-field-date>
    <ds-input-field-text
        [form]="formService.ccfInsured" 
        formControlNameInput="insuredLast4SSN"
        inputType="tel"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        errorMessage="{{formService.lbl.last4SSN}}{{formService.errMsg.required}}"
        label="{{formService.lbl.last4SSN}}"
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        [isRequired]="formService.isLifeClaim()"
        inputMask="{{formService.mask.last4SSN}}"
        ariaLabelInput="{{formService.lbl.insured}}{{formService.lbl.last4SSN}}">
    </ds-input-field-text>
    <ds-input-dropdownlist
        *ngIf="formService.isLifeClaim() || (formService.isADDClaim() && formService.isAccidentalDeathLoss())"
        [form]="formService.ccfInsured"
        formControlNameInput="insuredMaritalStatusAtTimeOfDeath"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        errorMessage="{{formService.lbl.maritalStatusDeath}}{{formService.errMsg.required}}"
        label="{{formService.lbl.maritalStatusDeath}}" 
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px" 
        [options]="formService.options.maritalStatus"
        [isRequired]="true"
        ariaLabelInput="{{formService.lbl.insured}}{{formService.lbl.maritalStatusDeath}}">
    </ds-input-dropdownlist>
    <ds-input-dropdownlist
        *ngIf="formService.isADDClaim() && (formService.isAccidentalDismembermentLoss() || formService.isAccidentalHospitalizationLoss() || formService.isCriticalIllnessLoss())"
        [form]="formService.ccfInsured"
        formControlNameInput="insuredMaritalStatusAtTimeOfLoss"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        errorMessage="{{formService.errMsg.requiredGeneric}}"
        label="{{formService.lbl.maritalStatusLoss}}" 
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px" 
        [options]="formService.options.maritalStatus"
        [isRequired]="true"
        ariaLabelInput="{{formService.lbl.insured}}{{formService.lbl.maritalStatusLoss}}">
    </ds-input-dropdownlist>
    <ds-input-dropdownlist
        *ngIf="formService.isADDClaim() && formService.isCriticalIllnessLoss()"
        [form]="formService.ccfInsured"
        formControlNameInput="illnessType"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        errorMessage="{{formService.errMsg.requiredGeneric}}"
        label="{{formService.lbl.illnessType}}" 
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px" 
        [options]="formService.options.illnessType"
        [isRequired]="true"
        ariaLabelInput="{{formService.lbl.insured}}{{formService.lbl.illnessType}}"
        (selectionChange)="selectionChange_illnessType()">
    </ds-input-dropdownlist>
    <ds-input-field-text
        *ngIf="formService.isADDClaim() && formService.isCriticalIllnessLoss() && illnessType_Cancer()"
        [form]="formService.ccfInsured" 
        formControlNameInput="cancerType"
        inputType="text"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        errorMessage="{{formService.lbl.cancerType}}{{formService.errMsg.required}}"
        label="{{formService.lbl.cancerType}}"
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        [isRequired]="false"
        ariaLabelInput="{{formService.lbl.insured}}{{formService.lbl.cancerType}}">
    </ds-input-field-text>
    <ds-input-field-text
        *ngIf="formService.isADDClaim() && formService.isCriticalIllnessLoss() && illnessType_Other()"
        [form]="formService.ccfInsured" 
        formControlNameInput="medicalDiagnosis"
        inputType="text"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        errorMessage="{{formService.lbl.medicalDiagnosis}}{{formService.errMsg.required}}"
        label="{{formService.lbl.medicalDiagnosis}}"
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        [isRequired]="false"
        ariaLabelInput="{{formService.lbl.insured}}{{formService.lbl.medicalDiagnosis}}">
    </ds-input-field-text>
    <ds-input-field-date
        *ngIf="formService.isADDClaim() && formService.isCriticalIllnessLoss()"
        [form]="formService.ccfInsured" 
        formControlNameInput="diagnosisDate"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        errorMessage="{{formService.lbl.diagnosisDate}}{{formService.errMsg.required}}"
        label="{{formService.lbl.diagnosisDate}}"
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        [isTouchUi]=configService.isClientMobile
        placeholderText="{{formService.placeholder.dateFormat}}"
        ariaLabelInput="{{formService.lbl.insured}}{{formService.lbl.diagnosisDate}}">
    </ds-input-field-date>
    <ds-input-field-date
        *ngIf="formService.isADDClaim() && (formService.isAccidentalDeathLoss() || formService.isAccidentalDismembermentLoss() || formService.isAccidentalHospitalizationLoss())"
        [form]="formService.ccfInsured" 
        formControlNameInput="accidentDate"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        errorMessage="{{formService.lbl.dateOfAccident}}{{formService.errMsg.required}}"
        label="{{formService.lbl.dateOfAccident}}"
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        [isTouchUi]=configService.isClientMobile
        placeholderText="{{formService.placeholder.dateFormat}}"
        ariaLabelInput="{{formService.lbl.insured}}{{formService.lbl.dateOfAccident}}">
    </ds-input-field-date>
    <ds-input-field-date
        *ngIf="formService.isADDClaim() && formService.isAccidentalDismembermentLoss()"
        [form]="formService.ccfInsured" 
        formControlNameInput="dismembermentDate"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        errorMessage="{{formService.lbl.dateOfDismemberment}}{{formService.errMsg.required}}"
        label="{{formService.lbl.dateOfDismemberment}}"
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        [isTouchUi]=configService.isClientMobile
        [isRequired]="false"
        placeholderText="{{formService.placeholder.dateFormat}}"
        ariaLabelInput="{{formService.lbl.insured}}{{formService.lbl.dateOfDismemberment}}">
    </ds-input-field-date>
    <ds-input-field-date
        *ngIf="formService.isLifeClaim() || (formService.isADDClaim() && (formService.isAccidentalDeathLoss() || formService.isNaturalDeathLoss()))"
        [form]="formService.ccfInsured" 
        formControlNameInput="insuredDateOfDeath"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        errorMessage="{{formService.lbl.dateOfDeath}}{{formService.errMsg.required}}"
        label="{{formService.lbl.dateOfDeath}}"
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        [isTouchUi]=configService.isClientMobile
        [isRequired]="true"
        placeholderText="{{formService.placeholder.dateFormat}}"
        ariaLabelInput="{{formService.lbl.insured}}{{formService.lbl.dateOfDeath}}">
    </ds-input-field-date>
    <ds-input-dropdownlist
        *ngIf="formService.isADDClaim() && formService.isNaturalDeathLoss()"
        [form]="formService.ccfInsured"
        formControlNameInput="causeOfDeath"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        errorMessage="{{formService.lbl.causeOfDeath}}{{formService.errMsg.required}}"
        label="{{formService.lbl.causeOfDeath}}" 
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px" 
        [options]="formService.options.causeOfDeath"
        [isRequired]="true"
        ariaLabelInput="{{formService.lbl.insured}}{{formService.lbl.causeOfDeath}}">
    </ds-input-dropdownlist>
    <ds-input-dropdownlist
        *ngIf="formService.isLifeClaim() || (formService.isADDClaim() && formService.isAccidentalDeathLoss())"
        [form]="formService.ccfInsured"
        formControlNameInput="insuredMannerOfDeath"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        errorMessage="{{formService.lbl.mannerOfDeath}}{{formService.errMsg.required}}"
        label="{{formService.lbl.mannerOfDeath}}" 
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px" 
        [options]="formService.options.mannerOfDeath"
        [isRequired]="true"
        (selectionChange)="formService.updateFormControls_insuredMannerOfDeath()"
        ariaLabelInput="{{formService.lbl.insured}}{{formService.lbl.mannerOfDeath}}">
    </ds-input-dropdownlist>
    <ds-input-field-yesnounsure 
        *ngIf="formService.isLifeClaim() && formService.isMannerOfDeathNatural()"
        [form]="formService.ccfInsured"
        formControlNameInput="insuredWasDeathDueToCovid19"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        errorMessage="{{formService.errMsg.requiredGeneric}}"
        label="{{formService.lbl.covid19Death}}" 
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        [isRequired]="true"
        ariaLabelInput="{{formService.lbl.covid19Death}}"> 
    </ds-input-field-yesnounsure>
    <ds-input-dropdownlist
        *ngIf="formService.isADDClaim() && ((formService.isAccidentalDeathLoss() && formService.isMannerOfDeathAccidental()) || formService.isAccidentalHospitalizationLoss())"
        [form]="formService.ccfInsured"
        formControlNameInput="accidentType"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        errorMessage="{{formService.lbl.accidentType}}{{formService.errMsg.required}}"
        label="{{formService.lbl.accidentType}}" 
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px" 
        [options]="formService.options.accidentType"
        [isRequired]="true"
        ariaLabelInput="{{formService.lbl.insured}}{{formService.lbl.accidentType}}">
    </ds-input-dropdownlist>
    <div class="row" *ngIf="(formService.isLifeClaim() && formService.isMannerOfDeathAccidental()) || (formService.isADDClaim() && (formService.isAccidentalDeathLoss() || formService.isAccidentalDismembermentLoss() || formService.isAccidentalHospitalizationLoss()))">
        <div class="col-md-3"></div>
        <div class="col-md-5 max-char-left">Max characters left: {{getAccidentMaxChar()}}</div>
    </div>
    <ds-input-field-text-large
        *ngIf="(formService.isLifeClaim() && formService.isMannerOfDeathAccidental()) || (formService.isADDClaim() && (formService.isAccidentalDeathLoss() || formService.isAccidentalDismembermentLoss() || formService.isAccidentalHospitalizationLoss()))"
        [form]="formService.ccfInsured" 
        inputType="text"
        formControlNameInput="insuredAccidentDescription"
        placeholderText="{{formService.lbl.accidentDescriptionPlaceholder}}"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        label="{{formService.lbl.accidentDescription}}"
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        ariaLabelInput="{{formService.lbl.accidentDescription}}"
        maxLength="200">
    </ds-input-field-text-large>
    <ds-input-field-text-large
        *ngIf="formService.isADDClaim() && formService.isAccidentalDismembermentLoss()"
        [form]="formService.ccfInsured" 
        inputType="text"
        formControlNameInput="insuredSpecificLoss"
        placeholderText="{{formService.lbl.specificLossPlaceholder}}"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        label="{{formService.lbl.specificLoss}}"
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        ariaLabelInput="{{formService.lbl.specificLoss}}"
        maxLength="200">
    </ds-input-field-text-large>
    <ds-input-field-daterange
        *ngIf="formService.isADDClaim() && (formService.isAccidentalDeathLoss() || formService.isAccidentalDismembermentLoss() || formService.isAccidentalHospitalizationLoss())"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        [form]="formService.ccfInsured" 
        label="{{formService.lbl.datesOfHospitalizationAccidentalInjury}}" 
        startDateName="hospitalizationFromDate"
        endDateName="hospitalizationToDate"
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        errorMessage="{{formService.errMsg.datesInvalid}}"
        placeholderStartDate={{formService.placeholder.dateFormat}}
        placeholderEndDate={{formService.placeholder.dateFormat}}
        [isTouchUi]=configService.isClientMobile
        [isStartDateRequired]="false"
        [isEndDateRequired]="false"
        (input)="input_clearDateArrayWhenInvalidDate(formService.ccfInsured.get(hospitalizationDatesFromProperty)?.value,formService.ccfInsured.get(hospitalizationDatesToProperty)?.value)">
    </ds-input-field-daterange>

    <ds-input-field-daterange-additional
        *ngIf="formService.isADDClaim() && formService.isAccidentalHospitalizationLoss() && hasValidDateRange(formService.ccfInsured.get(hospitalizationDatesFromProperty)?.value,formService.ccfInsured.get(hospitalizationDatesToProperty)?.value)"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        header="{{formService.lbl.otherHospitalizationDates}}"
        label="{{formService.lbl.additionalDates}}"
        headerClass="col-md-12 header"
        labelClass="col-md-4 indentedLabel"
        note="{{formService.lbl.additionalHospitalizationLimitNote}}"
        noteClass="col-md-10 note" 
        addButtonClass="col-md-4 padding-bottom-15px"
        addButtonLabel="{{formService.lbl.addHospitalization}}"
        addButtonColor="add"
        removeButtonClass="col-md-3"
        removeButtonLabel="{{formService.lbl.removeHospitalization}}"
        removeButtonColor="danger"
        divClass="col-md-8 padding-bottom-15px"
        listDivClass="row"
        dateRangeClass="col-md-7"
        startDateName="hospitalizationFromDate"
        endDateName="hospitalizationToDate"
        [isTouchUi]=configService.isClientMobile
        [form]="formService.ccfInsured"
        formArrayName={{additionalHospitalizationDatesProperty}}
        [maxElements]=2
        bufferClass="col-md-3"
        [isStartDateRequired]=false
        [isEndDateRequired]=false
        errorMessage="{{formService.errMsg.datesInvalid}}"
        startDatePlaceHolder={{formService.placeholder.dateFormat}}
        endDatePlaceHolder={{formService.placeholder.dateFormat}}>
    </ds-input-field-daterange-additional>
    <ds-input-field-yesnounsure 
        *ngIf="(formService.isLifeClaim()) || (formService.isADDClaim() && (formService.isAccidentalDeathLoss() || formService.isNaturalDeathLoss()))"
        [form]="formService.ccfInsured"
        formControlNameInput="insuredDidDeathOccurInForeignCountry"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        errorMessage="{{formService.errMsg.requiredGeneric}}"
        label="{{formService.lbl.foreignCountryDeath}}" 
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        [isRequired]="true"
        ariaLabelInput="{{formService.lbl.foreignCountryDeath}}"> 
    </ds-input-field-yesnounsure>
    <ds-input-field-yesnounsure 
        *ngIf="formService.isADDClaim() && (formService.isAccidentalDismembermentLoss() || formService.isAccidentalHospitalizationLoss())"
        [form]="formService.ccfInsured"
        formControlNameInput="didLossOccurInForeignCountry"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        errorMessage="{{formService.errMsg.requiredGeneric}}"
        label="{{formService.lbl.foreignCountryLoss}}" 
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        [isRequired]="true"
        ariaLabelInput="{{formService.lbl.foreignCountryLoss}}"> 
    </ds-input-field-yesnounsure>
    <ds-input-field-text
        *ngIf="formService.isLifeClaim() || (formService.isADDClaim() && (formService.isAccidentalDeathLoss() || formService.isNaturalDeathLoss()))"
        [form]="formService.ccfInsured" 
        formControlNameInput="insuredFuneralHomeName"
        inputType="text"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        label="{{formService.lbl.funeralName}}"
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        ariaLabelInput="{{formService.lbl.funeralName}}">
    </ds-input-field-text>
    <ds-input-field-text
        *ngIf="formService.isLifeClaim() || (formService.isADDClaim() && (formService.isAccidentalDeathLoss() || formService.isNaturalDeathLoss()))"
        [form]="formService.ccfInsured" 
        formControlNameInput="insuredFuneralHomeCity"
        inputType="text"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        label="{{formService.lbl.funeralCity}}"
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        ariaLabelInput="{{formService.lbl.funeralCity}}">
    </ds-input-field-text>
    <ds-state-selector
        *ngIf="formService.isLifeClaim() || (formService.isADDClaim() && (formService.isAccidentalDeathLoss() || formService.isNaturalDeathLoss()))"
        [form]="formService.ccfInsured"
        formControlNameInput="insuredFuneralHomeState"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        label="{{formService.lbl.funeralState}}" 
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        ariaLabelInput="{{formService.lbl.funeralState}}"> 
    </ds-state-selector>
    <ds-input-field-text-large
        *ngIf="formService.isLifeClaim() || (formService.isADDClaim() && (formService.isAccidentalDeathLoss() || formService.isNaturalDeathLoss()))"
        [form]="formService.ccfInsured" 
        formControlNameInput="insuredObituary"
        inputType="text"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        label="{{formService.lbl.obituary}}"
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        ariaLabelInput="{{formService.lbl.insured}}{{formService.lbl.obituary}}">
    </ds-input-field-text-large>
    <ds-input-field-text
        *ngIf="formService.isLifeClaim()"
        [form]="formService.ccfInsured" 
        formControlNameInput="insuredPolicyNumber"
        inputType="text"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        label="{{formService.lbl.policyNumber}}"
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        ariaLabelInput="{{formService.lbl.insured}}{{formService.lbl.policyNumber}}"
        inputMask="{{formService.mask.policyNumber}}"
        [maskValidation]="false">
    </ds-input-field-text>
    <ds-input-field-text
        *ngIf="formService.isADDClaim()"
        [form]="formService.ccfInsured" 
        formControlNameInput="certificateNumber"
        inputType="text"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        label="{{formService.lbl.certificateNumber}}"
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        ariaLabelInput="{{formService.lbl.insured}}{{formService.lbl.certificateNumber}}"
        inputMask="{{formService.mask.policyNumber}}"
        [maskValidation]="false">
    </ds-input-field-text>
    <ds-input-field-yesnounsure 
        *ngIf="formService.isADDClaim() && (formService.isAccidentalDeathLoss() || formService.isAccidentalDismembermentLoss() || formService.isAccidentalHospitalizationLoss())"
        [form]="formService.ccfInsured"
        formControlNameInput="isPrimaryInsuredOnCertificate"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        errorMessage="{{formService.errMsg.requiredGeneric}}"
        label="{{formService.lbl.isPrimaryInsuredOnCertificate}}" 
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        [isRequired]="true"
        ariaLabelInput="{{formService.lbl.isPrimaryInsuredOnCertificate}}"
        (selectionChange)="selectionChange_isPrimaryInsuredOnCertificate()"> 
    </ds-input-field-yesnounsure>
    <ds-input-field-yesnounsure 
        *ngIf="formService.isADDClaim() && (formService.isCriticalIllnessLoss() || (formService.isAccidentalDeathLoss() && isPrimaryInsuredOnCertificate_No()))"
        [form]="formService.ccfInsured"
        formControlNameInput="areYouPrimaryInsuredOnCertificate"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        errorMessage="{{formService.errMsg.requiredGeneric}}"
        label="{{formService.lbl.areYouPrimaryInsuredOnCertificate}}" 
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        [isRequired]="true"
        ariaLabelInput="{{formService.lbl.areYouPrimaryInsuredOnCertificate}}"
        (selectionChange)="selectionChange_areYouPrimaryInsuredOnCertificate()"> 
    </ds-input-field-yesnounsure>
    <ds-input-field-yesnounsure 
        *ngIf="formService.isADDClaim() && formService.isCriticalIllnessLoss() && areYouPrimaryInsuredOnCertificate_No()"
        [form]="formService.ccfInsured"
        formControlNameInput="isPrimaryInsuredOnCertificate"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        errorMessage="{{formService.errMsg.requiredGeneric}}"
        label="{{formService.lbl.isPrimaryInsuredOnCertificate}}" 
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        [isRequired]="true"
        ariaLabelInput="{{formService.lbl.isPrimaryInsuredOnCertificate}}"
        (selectionChange)="selectionChange_isPrimaryInsuredOnCertificate()"> 
    </ds-input-field-yesnounsure>
    <ds-input-field-text
        *ngIf="formService.isADDClaim() && formService.isAccidentalDeathLoss() && areYouPrimaryInsuredOnCertificate_No()"
        [form]="formService.ccfInsured" 
        formControlNameInput="livingPrimaryInsuredOnCertificate"
        inputType="text"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        placeholderText="Full name"
        label="{{formService.lbl.livingPrimaryInsuredOnCertificate}}"
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        ariaLabelInput="{{formService.lbl.insured}}{{formService.lbl.livingPrimaryInsuredOnCertificate}}"
        inputMask=""
        [maskValidation]="false"
        [isRequired]="false"
        (input)="input_livingPrimaryInsuredOnCertificate()">
    </ds-input-field-text>
    <ds-input-field-text
        *ngIf="formService.isADDClaim() && formService.isCriticalIllnessLoss() && isPrimaryInsuredOnCertificate_No()"
        [form]="formService.ccfInsured" 
        formControlNameInput="livingPrimaryInsuredOnCertificate"
        inputType="text"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        placeholderText="Full name"
        label="{{formService.lbl.livingPrimaryInsuredOnCertificate}}"
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        ariaLabelInput="{{formService.lbl.insured}}{{formService.lbl.livingPrimaryInsuredOnCertificate}}"
        inputMask=""
        [maskValidation]="false"
        [isRequired]="true"
        (input)="input_livingPrimaryInsuredOnCertificate()">
    </ds-input-field-text>
    <ds-input-dropdownlist
        *ngIf="formService.isADDClaim() && ((formService.isCriticalIllnessLoss() && isPrimaryInsuredOnCertificate_No()) || (formService.isAccidentalDeathLoss() && livingPrimaryInsuredOnCertificate_hasValue()))"
        [form]="formService.ccfInsured"
        formControlNameInput="relationshipToPrimaryInsuredOnCertificate"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        errorMessage="{{formService.errMsg.requiredGeneric}}"
        label="{{formService.lbl.relationshipToPrimaryInsuredOnCertificate}}" 
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px" 
        [options]="formService.options.relationshipToPrimaryInsuredOnCertificate"
        [isRequired]="true"
        ariaLabelInput="{{formService.lbl.insured}}{{formService.lbl.relationshipToPrimaryInsuredOnCertificate}}">
    </ds-input-dropdownlist>
    <ds-input-dropdownlist
        *ngIf="formService.isADDClaim() && formService.isAccidentalDeathLoss() && livingPrimaryInsuredOnCertificate_hasValue()"
        [form]="formService.ccfInsured"
        formControlNameInput="maritalStatusOfPrimaryInsuredAtTimeOfLoss"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        errorMessage="{{formService.errMsg.requiredGeneric}}"
        label="{{formService.lbl.maritalStatusOfPrimaryInsuredAtTimeOfLoss}}" 
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px" 
        [options]="formService.options.maritalStatus"
        [isRequired]="true"
        ariaLabelInput="{{formService.lbl.insured}}{{formService.lbl.maritalStatusOfPrimaryInsuredAtTimeOfLoss}}">
    </ds-input-dropdownlist>
    <ds-input-dropdownlist
        *ngIf="formService.isADDClaim() && formService.isCriticalIllnessLoss() && (areYouPrimaryInsuredOnCertificate_Unsure() || areYouPrimaryInsuredOnCertificate_No())"
        [form]="formService.ccfInsured"
        formControlNameInput="hasInsuredPassedAway"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        errorMessage="{{formService.errMsg.requiredGeneric}}"
        label="{{formService.lbl.hasInsuredPassedAway}}" 
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px" 
        [options]="formService.options.yesNo"
        [isRequired]="true"
        ariaLabelInput="{{formService.lbl.insured}}{{formService.lbl.hasInsuredPassedAway}}"
        (selectionChange)="selectionChange_hasInsuredPassedAway()">
    </ds-input-dropdownlist>
    <ds-input-field-date
        *ngIf="formService.isADDClaim() && formService.isCriticalIllnessLoss() && hasInsuredPassedAway_Yes()"
        [form]="formService.ccfInsured" 
        formControlNameInput="insuredDateOfDeath"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        errorMessage="{{formService.lbl.dateOfDeath}}{{formService.errMsg.required}}"
        label="{{formService.lbl.dateOfDeath}}"
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        [isTouchUi]=configService.isClientMobile
        [isRequired]="false"
        placeholderText="{{formService.placeholder.dateFormat}}"
        ariaLabelInput="{{formService.lbl.insured}}{{formService.lbl.dateOfDeath}}">
    </ds-input-field-date>
    <ds-input-field-text-large
        *ngIf="formService.isADDClaim() && formService.isCriticalIllnessLoss() && hasInsuredPassedAway_Yes()"
        [form]="formService.ccfInsured" 
        formControlNameInput="insuredObituary"
        inputType="text"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        label="{{formService.lbl.obituary}}"
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        ariaLabelInput="{{formService.lbl.insured}}{{formService.lbl.obituary}}">
    </ds-input-field-text-large>
    <div class="row" *ngIf="!formService.isSummaryStep()">
        <div class="col-md-6">
            <!-- Don't need a back button here, this is the first step. But let's keep this column here for consistency. -->
        </div>
        <div class="col-md-6 right-align">
            <button type="submit" [disabled]="form.submitted && formService.ccfInsured.invalid" mat-flat-button color="success">Continue</button>
        </div>
        <br><br>
        <p *ngIf="form.submitted && formService.ccfInsured.invalid" class="alert alert-danger">
            One or more fields are invalid. Please correct any invalid field(s) and try again.
        </p>
    </div>
</form>