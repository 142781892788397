export class ErrorMessages{
    required = ' is required.';
    requiredGeneric = 'This field is required.';
    claimType = 'Pick a claim type from the drop down list.';
    lossType = 'Pick a loss type from the drop down list.';
    formInvalid = "One or more fields are invalid. Please correct any invalid field(s) and try again."
    additionalBeneArrayInvalid = "One or more additional beneficiaries are invalid. Please correct any invalid fields and try again."
    datesInvalid = "Please correct any invalid dates and try again."
    oneOrMoreInvalid = " has one or more invalid fields."
    criticalIllnessWarning = "You have selected Cancer, Heart Attack or Stroke Rider as the loss type.  This refers to a rider that some of our accidental death and dismemberment coverages issued after 07/01/2019 <u>may include if requested during the application process and benefit remains active</u>.  This critical illness rider is for diagnosis of cancer, heart attack, or a stroke.  If the insured’s policy does not carry this rider or was issued prior to 07/01/2019 please refrain from selecting this option for the loss type and use a different option or contact us at 1-800-779-5433 for assistance with submitting this claim."

    fileUploadRequired(sizeLimitMB){
        return 'At least one file is required. Your file(s) must not exceed ' + sizeLimitMB +' megabytes.'
    };
}