<p>
    <b>Under penalties of perjury, I certify that:</b><br>
    1. I am a U.S. person (including a U.S. resident alien) <b>and</b><br>
    2. I am not subject to backup withholding because: (a) I am exempt from backup withholding, or (b) I have not been notified 
        by the Internal Revenue Service (IRS) that I am subject to backup withholding as a result of a failure to report all 
        interest or dividends, or (c) the IRS has notified me that I am no longer subject to backup withholding, <b>and</b><br>
    3. The number shown on this form is my correct taxpayer identification number (TIN).<br>
    4. The FATCA code(s) entered on this form (if any) indicating that I am exempt from FATCA reporting is correct. (Not Applicable).<br>
    <b>Certification Instructions.</b>  You must cross out item <b>2</b> above if you have been notified by the IRS that you are 
        currently subject to backup withholding because you have failed to report all interest and dividends on your tax return. 
        For payments other than interest, you generally are not required to sign the Certification, but you must provide your 
        correct TIN.
</p>