<form name="additionalBenes" #form="ngForm" aria-label="Claim form" (ngSubmit)="formService.onSubmitArray(beneForms)">
    <h4 #addlBeneInfo>{{formService.lbl.addlBeneMainHeader}}</h4>
    <mat-tab-group #addlBeneTabs mat-align-tabs="start" *ngIf="beneForms.length > 0" dynamicHeight [(selectedIndex)]="selectedIndex">
        <mat-tab *ngFor="let additionalBene of beneForms.controls; index as i">
            <ng-template mat-tab-label>
                <label>{{formService.lbl.beneficiary}} {{i+2}}</label>
                <button mat-icon-button (click)="deleteBene(i)" *ngIf="beneForms.length>1">
                    <mat-icon style="color:red" >highlight_off</mat-icon>
                </button>
            </ng-template>
            <app-form-group-beneficiary 
                [bene]='castAdditionalBene(additionalBene)' [isPrimaryBene]="false">
            </app-form-group-beneficiary>
            <div class="row">
                <div class="col-md-5 offset-md-3" style="padding-bottom: 40px">
                    <button type="button" *ngIf="formService.ccfAdditionalBenes.length < 4" class="add-button"(click)="addBene(addlBeneTabs); scroll(addlBeneInfo)"mat-flat-button color="add">{{formService.lbl.addAddlBene}}</button>
                </div>
            </div>
        </mat-tab>  
    </mat-tab-group>
    <div class="row"> 
        <div class="col-md-6"  *ngIf="!formService.isSummaryStep()">
            <button type="button" (click)="formService.back()" mat-flat-button color="danger">{{formService.lbl.back}}</button>
        </div>
        <div class="col-md-6 right-align"  *ngIf="!formService.isSummaryStep()">
            <button type="submit"[disabled]="form.submitted && formService.ccfAdditionalBenes.invalid" mat-flat-button color="success">{{formService.lbl.continue}}</button>
        </div>
        <br><br>
        <div *ngIf="(form.submitted || formService.isSummaryStep()) && formService.ccfAdditionalBenes.invalid" class="alert alert-danger">
            <p *ngIf="!formService.isSummaryStep()">{{errorMsgs.additionalBeneArrayInvalid}}</p>
            <div *ngFor="let additionalBene of invalidAdditionalBenes">
                <div class="row">
                    <div>
                        <a (click)="jumpToError(additionalBene, addlBeneInfo)" style="cursor:pointer; color:blue"><u>{{formService.lbl.beneficiary}} {{additionalBene+2}}</u></a>{{errorMsgs.oneOrMoreInvalid}}
                    </div>            
                </div>
            </div>
        </div>
    </div>
</form>
