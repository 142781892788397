import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConsumerClaimSchema } from '../models/consumer-claim-schema';
import { SchemaMapperService } from './schema-mapper.service';
import { ConsumerClaimForm } from '../models/consumer-claim-form';
import { ApiPath } from '../models/api';
import { GuaranteedDeliveryRequestBody } from '../models/guaranteed-delivery-request-body';

@Injectable({
  providedIn: 'root'
})
export class GuaranteedDeliveryService {

  api:ApiPath = new ApiPath();
  consumerClaimsSchema:ConsumerClaimSchema = new ConsumerClaimSchema();

  private ccgdSubmitRequestBody: GuaranteedDeliveryRequestBody = new GuaranteedDeliveryRequestBody();

  submitClaim(consumerClaimForm:ConsumerClaimForm, recaptchaToken:string){

    this.consumerClaimsSchema = this.schemaMapperService.mapConsumerClaimsSchema(this.consumerClaimsSchema, consumerClaimForm);

    this.ccgdSubmitRequestBody.data = btoa(JSON.stringify(this.consumerClaimsSchema))

    return this.http.post(this.api.guaranteedDelivery, this.ccgdSubmitRequestBody, { headers: this.api.apicBaseHeadersRecaptchaToken(recaptchaToken), responseType: 'text' });
    
  }

  constructor(
    private http: HttpClient,
    private schemaMapperService: SchemaMapperService
  ) { }
}
