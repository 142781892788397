<form [formGroup]="bene" #form="ngForm" aria-label="Claim form" (ngSubmit)="formService.onSubmit(bene)">
    <h4 *ngIf="isPrimaryBene">Please provide information about the beneficiary</h4>
    <br><br>
    <ds-input-field-text
        [form]="bene" 
        formControlNameInput="beneFirstName"
        inputType="text"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        errorMessage="{{formService.lbl.beneficiary}}{{formService.lbl.firstName}}{{formService.errMsg.required}}"
        label="{{formService.lbl.beneficiary}}{{formService.lbl.firstName}}"
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        [isRequired]="true"
        ariaLabelInput="{{formService.lbl.beneficiary}}{{formService.lbl.firstName}}">
    </ds-input-field-text>
    <ds-input-field-text
        [form]="bene" 
        formControlNameInput="beneLastName"
        inputType="text"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        errorMessage="{{formService.lbl.beneficiary}}{{formService.lbl.lastName}}{{formService.errMsg.required}}"
        label="{{formService.lbl.beneficiary}}{{formService.lbl.lastName}}"
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        [isRequired]="true"
        ariaLabelInput="{{formService.lbl.beneficiary}}{{formService.lbl.firstName}}">
    </ds-input-field-text>
    <ds-country-selector
        [form]="bene"
        formControlNameInput="beneCountry"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        errorMessage="{{formService.lbl.country}}{{formService.errMsg.required}}"
        label="{{formService.lbl.country}}" 
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        [isRequired]="true"
        (selectionChange)="formService.updateBeneFormFormControls_beneCountry(bene)"
        ariaLabelInput="{{formService.lbl.beneficiary}}{{formService.lbl.country}}"> 
    </ds-country-selector>
    <ds-input-field-text
        *ngIf="formService.isBeneFormCountryOther(bene)"
        [form]="bene" 
        formControlNameInput="beneOtherCountryName"
        inputType="text"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        errorMessage="{{formService.lbl.countryOther}}{{formService.errMsg.required}}"
        label="{{formService.lbl.countryOther}}"
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        [isRequired]="true"
        ariaLabelInput="{{formService.lbl.beneficiary}}{{formService.lbl.countryOther}}">
    </ds-input-field-text>
    <ds-input-field-text
        [form]="bene" 
        formControlNameInput="beneAddress"
        inputType="text"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        errorMessage="{{formService.lbl.address}}{{formService.errMsg.required}}"
        label="{{formService.lbl.address}}"
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        [isRequired]="true"
        ariaLabelInput="{{formService.lbl.beneficiary}}{{formService.lbl.address}}">
    </ds-input-field-text>
    <ds-input-field-text
        [form]="bene" 
        formControlNameInput="beneCity"
        inputType="text"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        errorMessage="{{formService.lbl.city}}{{formService.errMsg.required}}"
        label="{{formService.lbl.city}}"
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        [isRequired]="true"
        ariaLabelInput="{{formService.lbl.beneficiary}}{{formService.lbl.city}}">
    </ds-input-field-text>
    <ds-state-selector
        *ngIf="!formService.isBeneFormCountryNotUsa(bene)"
        [form]="bene"
        formControlNameInput="beneState"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        label="{{formService.lbl.state}}" 
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        [isRequired]="true"
        errorMessage="{{formService.lbl.state}}{{formService.errMsg.required}}"
        ariaLabelInput="{{formService.lbl.beneficiary}}{{formService.lbl.state}}"> 
    </ds-state-selector>
    <ds-input-field-text
        [form]="bene" 
        formControlNameInput="beneZipCode"
        inputType="tel"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        errorMessage="{{formService.lbl.zipCode}}{{formService.errMsg.required}}"
        label="{{formService.lbl.zipCode}}"
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        [isRequired]="!formService.isBeneFormCountryNotUsa(bene)"
        inputMask="{{formService.mask.zipCode}}"
        ariaLabelInput="{{formService.lbl.beneficiary}}{{formService.lbl.zipCode}}">
    </ds-input-field-text>
    <ds-input-field-text
        [form]="bene" 
        formControlNameInput="benePhone"
        inputType="tel"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        errorMessage="{{formService.lbl.phone}}{{formService.errMsg.required}}"
        label="{{formService.lbl.phone}}"
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        [isRequired]="true"
        inputMask="{{formService.mask.phone}}"
        ariaLabelInput="{{formService.lbl.beneficiary}}{{formService.lbl.phone}}">
    </ds-input-field-text>
    <ds-input-field-text
        [form]="bene" 
        formControlNameInput="beneEmail"
        inputType="email"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        label="{{formService.lbl.email}}"
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        ariaLabelInput="{{formService.lbl.beneficiary}}{{formService.lbl.email}}">
    </ds-input-field-text>
    <ds-input-dropdownlist
        [form]="bene"
        formControlNameInput="beneGender"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        errorMessage="{{formService.lbl.gender}}{{formService.errMsg.required}}"
        label="{{formService.lbl.gender}}" 
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px" 
        [options]="formService.options.gender"
        [isRequired]="true"
        ariaLabelInput="{{formService.lbl.beneficiary}}{{formService.lbl.gender}}">
    </ds-input-dropdownlist>
    <ds-input-field-date
        [form]="bene" 
        formControlNameInput="beneBirthDate"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        errorMessage="{{formService.lbl.beneficiary}}{{formService.lbl.birthDate}}{{formService.errMsg.required}}"
        label="{{formService.lbl.beneficiary}}{{formService.lbl.birthDate}}"
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        [isTouchUi]=configService.isClientMobile
        [isRequired]="false"
        placeholderText="{{formService.placeholder.dateFormat}}"
        ariaLabelInput="{{formService.lbl.beneficiary}}{{formService.lbl.birthDate}}">
    </ds-input-field-date>
    <ds-input-field-text-secret
        [form]="bene" 
        formControlNameInput="beneSSN"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        errorMessage="{{formService.lbl.beneficiary}}{{formService.lbl.ssn}}{{formService.errMsg.required}}"
        label="{{formService.lbl.beneficiary}}{{formService.lbl.ssn}}"
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px"
        [isRequired]="false"
        inputMask="{{formService.mask.SSN}}"
        ariaLabelInput="{{formService.lbl.beneficiary}}{{formService.lbl.ssn}}">
    </ds-input-field-text-secret>
    <ds-input-dropdownlist 
        *ngIf="isPrimaryBene"
        [form]="bene"
        formControlNameInput="hasAdditionalBenes"
        matFormFieldAppearance="outline"
        matFormFieldClass="input-width-max"
        errorMessage="{{formService.errMsg.requiredGeneric}}"
        label="{{formService.lbl.addlBeneQuestion}}" 
        divClass="col-md-5 padding-bottom-15px"
        labelClass="col-md-3 padding-bottom-15px" 
        [options]="formService.options.yesNo"
        [isRequired]="isPrimaryBene"
        ariaLabelInput="{{formService.lbl.beneficiary}}{{formService.lbl.addlBeneQuestion}}"
        (selectionChange)="onChange()">
    </ds-input-dropdownlist>
    <div class="row" *ngIf="!formService.isSummaryStep() && !formService.isAdditionalBeneficiaryStep()">
        <div class="col-md-6">
            <button type="submit" (click)="formService.back()" mat-flat-button color="danger">{{formService.lbl.back}}</button>
        </div>
        <div class="col-md-6 right-align">
            <button type="submit" [disabled]="form.submitted && bene.invalid" mat-flat-button color="success">{{formService.lbl.continue}}</button>
        </div>
        <br><br>
        <p *ngIf="form.submitted && bene.invalid" class="alert alert-danger">
            {{errorMsgs.formInvalid}}
        </p>
    </div>
</form>
