import { FormControl, FormGroup, Validators } from "@angular/forms";

export class FileUploadForm{
  
  formGroupFileUpload = new FormGroup({
    claimNumber: new FormControl(''),
    file: new FormControl('', Validators.required)
  });
  formGroupRecaptcha = new FormGroup({
    recaptcha: new FormControl('', Validators.required)
  });

}