import { IClaim } from "../interfaces/IClaim";
import { Beneficiary } from "./beneficiary";
import { ConsumerClaimProperty } from "./consumer-claim-property";
import { Informant } from "./informant";
import { Death } from "./death";
import { Insured } from "./insured";

export class Claim implements IClaim{
    claimType: string;
    lossType: string;
    insured: Insured;
    death: Death;
    contact: Informant;
    beneficiary: Array<Beneficiary>;
    consumerClaimProperties: Array<ConsumerClaimProperty>

    constructor(){
        this.claimType = '';
        this.lossType = '';
        this.insured = new Insured();
        this.death = new Death();
        this.contact = new Informant();
        this.consumerClaimProperties = new Array<ConsumerClaimProperty>();
    }
}