import { Component, OnInit } from '@angular/core';
import { FileUploadService } from 'src/app/services/file-upload.service';

@Component({
  selector: 'app-file-upload-success',
  templateUrl: './file-upload-success.component.html',
  styleUrls: ['./file-upload-success.component.scss'],
  standalone: true
})
export class FileUploadSuccessComponent implements OnInit {

  constructor(
    public fileUploadService: FileUploadService) { }

  ngOnInit(): void {
  }

}
