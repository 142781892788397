<h3>CLAIM SUBMITTED</h3>
<br>

<span *ngIf="formService.isLifeClaim() || (formService.isADDClaim() && (formService.isAccidentalDeathLoss() || formService.isNaturalDeathLoss()))">
    <b>Next Steps:</b> Thank you for using our online claims submission. <b>Your claim notice has been submitted and there is no need to contact us at this time.</b> 
    One of our claim specialists will begin review of the information you provided. We are so sorry for your loss, and we will do everything we can to help you through the claims process. 
    <span *ngIf="formService.isLifeClaim()">If the life insurance policy is less than two years old, we will send forms to the beneficiary to complete in the mail.</span>
</span>
<span *ngIf="formService.isADDClaim() && (formService.isCriticalIllnessLoss() || formService.isAccidentalDismembermentLoss() || formService.isAccidentalHospitalizationLoss())">
    <b>Next Steps:</b> Thank you for using our online claims submission. <b>Your claim notice has been submitted and there is no need to contact us at this time.</b> 
    One of our claim specialists will begin review of the information you provided. We will do everything we can to help you through the claims process.
</span>

