<app-header></app-header>
<div class="container-fluid">
    <div class="row cmg-t-white cmg-body border-left-right">
        <div class="col-md-9">
            <div style="padding: 10px">
                <router-outlet></router-outlet>
            </div>
        </div>
        <div class="col-md-3">
            <app-nav-right></app-nav-right>
        </div>
    </div>
</div>
<app-footer></app-footer>