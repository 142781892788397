import { Component, OnInit } from '@angular/core';
import { FormSteps } from 'src/app/enumerations/enum-form-steps';
import { LossType } from 'src/app/enumerations/enum-loss-type';
import { ConsumerClaimForm } from 'src/app/models/consumer-claim-form';
import { DropDownOptions } from 'src/app/models/dropdown-options';
import { Labels } from 'src/app/models/labels';
import { FormService } from 'src/app/services/form.service';
import { GuaranteedDeliveryService } from 'src/app/services/guaranteed-delivery.service';
import { NgIf } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';
import { ReactiveFormsModule } from '@angular/forms';
import { FormGroupComponent } from '../form-group/form-group.component';
import { TaxLanguageComponent } from '../tax-language/tax-language.component';
import { InputDropdownlistComponent } from '@ds/claims-portal-ui-components';
import { FraudLanguageComponent } from '../fraud-language/fraud-language.component';

@Component({
  selector: 'app-claim-workflow',
  templateUrl: './claim-workflow.component.html',
  styleUrls: ['./claim-workflow.component.scss'],
  standalone: true,
  imports: [NgIf, MatExpansionModule, MatDividerModule, ReactiveFormsModule, FormGroupComponent, InputDropdownlistComponent, TaxLanguageComponent, FraudLanguageComponent]
})

export class ClaimWorkflowComponent implements OnInit {

  panelOpenState = true;

  updateClaimSteps(){
    this.initDynamicFormHelpers();
    if(this.formService.isLifeClaim()){
      this.formService.ccfInsured.controls.lossType.setValue(LossType.NotSet)
      this.formService.formStep = FormSteps.Insured;
    }else if(this.formService.isADDClaim() && !this.formService.lossTypeNotSet()){
      this.formService.formStep = FormSteps.Insured;
      this.panelOpenState = false;
    }else{
      this.formService.formStep = FormSteps.NotSet;
    }
    this.formService.updateFormGroup_nonBeneficiary()
    
  }
  
  initDynamicFormHelpers(){
    this.formService.lbl = new Labels(this.formService.ccfInsured.value.claimType, this.formService.ccfInsured.value.lossType)
    this.formService.options = new DropDownOptions(this.formService.ccfInsured.value.claimType, this.formService.ccfInsured.value.lossType)
  }

  constructor(
    public guaranteedDeliveryService: GuaranteedDeliveryService,
    public formService: FormService) { }

  ngOnInit(): void {
    if(this.formService.claimSubmitSuccess === true){
      //If user submitted claim and re-loaded this componenet, instantiate new ConsumerClaimForm. This will prevent duplicate entries.
      this.formService.consumerClaimForm = new ConsumerClaimForm();
    }
  }

}
