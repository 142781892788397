<b>
    <p>
        FRAUD WARNING: ANY PERSON WHO KNOWINGLY PRESENTS A FALSE OR FRAUDULENT CLAIM FOR PAYMENT OF A LOSS OR BENEFIT, 
        OR KNOWINGLY PRESENTS FALSE INFORMATION IN AN APPLICATION FOR INSURANCE MAY BE GUILTY OF A CRIME AND SUBJECT TO 
        FINES AND CONFINEMENT IN PRISON, AND DENIAL OF INSURANCE BENEFITS, DEPENDING ON STATE LAW.
        </p>
        
        <p>
        ALABAMA, ARKANSAS AND MARYLAND RESIDENTS: ANY PERSON WHO KNOWINGLY OR WILLFULLY PRESENTS A FALSE OR FRAUDULENT 
        CLAIM FOR PAYMENT OF A LOSS OR BENEFIT OR WHO KNOWINGLY OR WILLFULLY PRESENTS FALSE INFORMATION IN AN APPLICATION 
        FOR INSURANCE IS GUILTY OF A CRIME AND MAY BE SUBJECT TO FINES AND CONFINEMENT IN PRISON.
        </p>
        
        <p>
        CALIFORNIA RESIDENTS: FOR YOUR PROTECTION, CALIFORNIA LAW REQUIRES THAT INSURERS INFORM YOU OF THE FOLLOWING: 
        ANY PERSON WHO KNOWINGLY PRESENTS FALSE OR FRAUDULENT INFORMATION TO OBTAIN OR AMEND INSURANCE COVERAGE OR TO MAKE 
        A CLAIM FOR THE PAYMENT OF A LOSS IS GUILTY OF A CRIME AND MAY BE SUBJECT TO FINES AND CONFINEMENT IN STATE PRISON.
        </p>
        
        <p>
        COLORADO RESIDENTS: IT IS UNLAWFUL TO KNOWINGLY PROVIDE FALSE, INCOMPLETE, OR MISLEADING FACTS OR INFORMATION TO AN INSURANCE COMPANY FOR THE PURPOSE OF DEFRAUDING OR ATTEMPTING TO DEFRAUD THE COMPANY. PENALTIES MAY INCLUDE IMPRISONMENT, FINES, DENIAL OF INSURANCE, AND CIVIL DAMAGES. ANY INSURANCE COMPANY OR AGENT OF AN INSURANCE COMPANY WHO KNOWINGLY PROVIDES FALSE, INCOMPLETE, OR MISLEADING FACTS OR INFORMATION TO A POLICYHOLDER OR CLAIMANT FOR THE PURPOSE OF DEFRAUDING OR ATTEMPTING TO DEFRAUD THE POLICYHOLDER OR CLAIMANT WITH REGARD TO A SETTLEMENT OR AWARD PAYABLE FROM INSURANCE PROCEEDS SHALL BE REPORTED TO THE COLORADO DIVISION OF INSURANCE WITHIN THE DEPARTMENT OF REGULATORY AGENCIES.
        </p>
        
        <p>
        DELAWARE RESIDENTS: ANY PERSON WHO KNOWINGLY, AND WITH INTENT TO INJURE, DEFRAUD OR DECEIVE ANY INSURER, FILES A STATEMENT OF CLAIM CONTAINING ANY FALSE, INCOMPLETE, OR MISLEADING INFORMATION IS GUILTY OF A FELONY.
        </p>
        
        <p>
        DISTRICT OF COLUMBIA RESIDENTS: WARNING: IT IS A CRIME TO PROVIDE FALSE OR MISLEADING INFORMATION TO AN INSURER FOR THE PURPOSE OF DEFRAUDING THE INSURER OR ANY OTHER PERSON. PENALTIES INCLUDE IMPRISONMENT AND/OR FINES. IN ADDITION, AN INSURER MAY DENY INSURANCE BENEFITS, IF FALSE INFORMATION MATERIALLY RELATED TO A CLAIM WAS PROVIDED BY THE APPLICANT.
        </p>
        
        <p>
        FLORIDA RESIDENTS: ANY PERSON WHO KNOWINGLY AND WITH INTENT TO INJURE, DEFRAUD OR DECEIVE ANY INSURER FILES A STATEMENT OF CLAIM OR AN APPLICATION CONTAINING ANY FALSE, INCOMPLETE, OR MISLEADING INFORMATION IS GUILTY OF A FELONY OF THE THIRD DEGREE.
        </p>
        
        <p>
        IDAHO RESIDENTS: ANY PERSON WHO KNOWINGLY, AND WITH INTENT TO DEFRAUD OR DECEIVE ANY INSURANCE COMPANY, FILES A STATEMENT OF CLAIM CONTAINING ANY FALSE, INCOMPLETE, OR MISLEADING INFORMATION IS GUILTY OF A FELONY.
        </p>
        
        <p>
        INDIANA: A PERSON WHO KNOWINGLY AND WITH INTENT TO DEFRAUD AN INSURER FILES A STATEMENT OF CLAIM CONTAINING ANY FALSE, INCOMPLETE, OR MISLEADING INFORMATION COMMITS A FELONY.
        </p>
        
        <p>
        KENTUCKY: ANY PERSON WHO KNOWINGLY AND WITH INTENT TO DEFRAUD ANY INSURANCE COMPANY OR OTHER PERSON FILES A STATEMENT OF CLAIM CONTAINING ANY MATERIALLY FALSE INFORMATION OR CONCEALS, FOR THE PURPOSE OF MISLEADING, INFORMATION CONCERNING ANY FACT MATERIAL THERETO COMMITS A FRAUDULENT INSURANCE ACT, WHICH IS A CRIME.
        </p>
        
        <p>
        LOUISIANA RESIDENTS: ANY PERSON WHO KNOWINGLY PRESENTS A FALSE OR FRAUDULENT CLAIM FOR PAYMENT OF A LOSS OR BENEFIT OR KNOWINGLY PRESENTS FALSE INFORMATION IN AN APPLICATION FOR INSURANCE IS GUILTY OF A CRIME AND MAY BE SUBJECT TO FINES AND CONFINEMENT IN PRISON.
        </p>
        
        <p>
        MAINE RESIDENTS: IT IS A CRIME TO KNOWINGLY PROVIDE FALSE, INCOMPLETE, OR MISLEADING INFORMATION TO AN INSURANCE COMPANY FOR THE PURPOSE OF DEFRAUDING THE COMPANY. PENALTIES MAY INCLUDE IMPRISONMENT, FINES, OR A DENIAL OF INSURANCE BENEFITS.
        </p>
        
        <p>
        MINNESOTA RESIDENTS: A PERSON WHO FILES A CLAIM WITH INTENT TO DEFRAUD OR HELPS COMMIT A FRAUD AGAINST AN INSURER IS GUILTY OF A CRIME.
        </p>
        
        <p>
        NEW HAMPSHIRE RESIDENTS: ANY PERSON WHO, WITH A PURPOSE TO INJURE, DEFRAUD OR DECEIVE ANY INSURANCE COMPANY, FILES A STATEMENT OF CLAIM CONTAINING ANY FALSE, INCOMPLETE OR MISLEADING INFORMATION IS SUBJECT TO PROSECUTION AND PUNISHMENT FOR INSURANCE FRAUD, AS PROVIDED IN RSA 638:20.
        </p>
        
        <p>
        NEW JERSEY RESIDENTS: ANY PERSON WHO KNOWINGLY FILES A STATEMENT OF CLAIM CONTAINING ANY FALSE OR MISLEADING INFORMATION IS SUBJECT TO CRIMINAL AND CIVIL PENALTIES.
        </p>
        
        <p>
        NEW MEXICO RESIDENTS: ANY PERSON WHO KNOWINGLY PRESENTS A FALSE OR FRAUDULENT CLAIM FOR PAYMENT OF A LOSS OR BENEFIT OR KNOWINGLY PRESENTS FALSE INFORMATION IN AN APPLICATION FOR INSURANCE IS GUILTY OF A CRIME AND MAY BE SUBJECT TO CIVIL FINES AND CRIMINAL PENALTIES.
        </p>
        
        <p>
        NEW YORK RESIDENTS: ANY PERSON WHO KNOWINGLY AND WITH INTENT TO DEFRAUD ANY INSURANCE COMPANY OR OTHER PERSON FILES AN APPLICATION FOR INSURANCE OR STATEMENT OF CLAIM CONTAINING ANY MATERIALLY FALSE INFORMATION, OR CONCEALS FOR THE PURPOSE OF MISLEADING, INFORMATION CONCERNING ANY FACT MATERIAL THERETO, COMMITS A FRAUDULENT INSURANCE ACT, WHICH IS A CRIME, AND SHALL ALSO BE SUBJECT TO CIVIL PENALTY NOT TO EXCEED $5,000 AND THE STATED VALUE OF THE CLAIM FOR EACH SUCH VIOLATION.
        </p>
        
        <p>
        OHIO RESIDENTS: ANY PERSON WHO, WITH INTENT TO DEFRAUD OR KNOWING THAT HE IS FACILITATING A FRAUD AGAINST AN INSURER, SUBMITS AN APPLICATION, OR FILES A CLAIM CONTAINING A FALSE OR DECEPTIVE STATEMENT IS GUILTY OF INSURANCE FRAUD.
        </p>
        
        <p>
        OKLAHOMA RESIDENTS: WARNING: ANY PERSON WHO KNOWINGLY, AND WITH INTENT TO INJURE, DEFRAUD OR DECEIVE ANY INSURER, MAKES ANY CLAIM FOR THE PROCEEDS OF AN INSURANCE POLICY CONTAINING ANY FALSE, INCOMPLETE, OR MISLEADING INFORMATION IS GUILTY OF A FELONY.
        </p>
        
        <p>
        PENNSYLVANIA RESIDENTS: ANY PERSON WHO KNOWINGLY AND WITH INTENT TO DEFRAUD ANY INSURANCE COMPANY OR OTHER PERSON FILES AN APPLICATION FOR INSURANCE OR A STATEMENT OF CLAIM CONTAINING ANY MATERIALLY FALSE INFORMATION OR CONCEALS FOR THE PURPOSE OF MISLEADING, INFORMATION CONCERNING ANY FACT MATERIAL THERETO COMMITS A FRAUDULENT INSURANCE ACT, WHICH IS A CRIME AND SUBJECTS SUCH PERSON TO CRIMINAL AND CIVIL PENALTIES.
        </p>
        
        <p>
        PUERTO RICO RESIDENTS: ANY PERSON WHO KNOWINGLY AND WITH THE INTENTION OF DEFRAUDING PRESENTS FALSE INFORMATION IN AN INSURANCE APPLICATION, OR PRESENTS, HELPS, OR CAUSES THE PRESENTATION OF A FRAUDULENT CLAIM FOR THE PAYMENT OF A LOSS OR ANY OTHER BENEFIT, OR PRESENTS MORE THAN ONE CLAIM FOR THE SAME DAMAGE OR LOSS, SHALL INCUR A FELONY AND, UPON CONVICTION, SHALL BE SANCTIONED FOR EACH VIOLATION WITH THE PENALTY OF A FINE OF NOT LESS THAN FIVE THOUSAND DOLLARS ($5,000) AND NOT MORE THAN TEN THOUSAND DOLLARS ($10,000), OR A FIXED TERM OF IMPRISONMENT FOR THREE (3) YEARS, OR BOTH PENALTIES. SHOULD AGGRAVATING CIRCUMSTANCES ARE PRESENT, THE PENALTY THUS ESTABLISHED MAY BE INCREASED TO A MAXIMUM OF FIVE (5) YEARS, IF EXTENUATING CIRCUMSTANCES BE PRESENT, IT MAY BE REDUCED TO A MINIMUM OF TWO (2) YEARS.
        </p>
        
        <p>
        RHODE ISLAND RESIDENTS: ANY PERSON WHO KNOWINGLY PRESENTS A FALSE OR FRAUDULENT CLAIM FOR PAYMENT OF A LOSS OR BENEFIT OR KNOWINGLY PRESENTS FALSE INFORMATION IN AN APPLICATION FOR INSURANCE IS GUILTY OF A CRIME AND MAY BE SUBJECT TO FINES AND CONFINEMENT IN PRISON.
        </p>
        
        <p>
        TENNESSEE RESIDENTS: IT IS A CRIME TO KNOWINGLY PROVIDE FALSE, INCOMPLETE, OR MISLEADING INFORMATION TO AN INSURANCE COMPANY FOR THE PURPOSE OF DEFRAUDING THE COMPANY. PENALTIES INCLUDE IMPRISONMENT, FINES, AND DENIAL OF INSURANCE BENEFITS.
        </p>
        
        <p>
        TEXAS RESIDENTS: ANY PERSON WHO KNOWINGLY PRESENTS A FALSE OR FRAUDULENT CLAIM FOR THE PAYMENT OF A LOSS IS GUILTY OF A CRIME AND MAY BE SUBJECT TO FINES AND CONFINEMENT IN STATE PRISON.
        </p>
        
        <p>
        VIRGINIA RESIDENTS: IT IS A CRIME TO KNOWINGLY PROVIDE FALSE, INCOMPLETE, OR MISLEADING INFORMATION TO AN INSURANCE COMPANY FOR THE PURPOSE OF DEFRAUDING THE COMPANY. PENALTIES INCLUDE IMPRISONMENT, FINES, AND DENIAL OF INSURANCE BENEFITS.
        </p>
        
        <p>
        WASHINGTON RESIDENTS: IT IS A CRIME TO KNOWINGLY PROVIDE FALSE, INCOMPLETE, OR MISLEADING INFORMATION TO AN INSURANCE COMPANY FOR THE PURPOSE OF DEFRAUDING THE COMPANY. PENALTIES INCLUDE IMPRISONMENT, FINES, AND DENIAL OF INSURANCE BENEFITS.
        </p>
        
        <p>
        WEST VIRGINIA RESIDENTS: ANY PERSON WHO KNOWINGLY PRESENTS A FALSE OR FRAUDULENT CLAIM FOR PAYMENT OF A LOSS OR BENEFIT OR KNOWINGLY PRESENTS FALSE INFORMATION IN AN APPLICATION FOR INSURANCE IS GUILTY OF A CRIME AND MAY BE SUBJECT TO FINES AND CONFINEMENT IN PRISON.
        </p>
</b>
